import { FormattedMessage } from "react-intl";
import styles from "./shareFooter.module.scss";

import { Modal, Separator } from "components";
import Button from "components/Button";
import { useAppNotification } from "hooks/services/AppNotification";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { useIntl } from "react-intl";

interface IProps {
    onClose: () => void;
    shareLink: string;
}

export const IOSModel = ({
    onClose,
    shareLink,
}: IProps) => {

    const { triggerNotification } = useAppNotification();
    const { formatMessage } = useIntl();
    
    return (
        <Modal size="sm" onClose={onClose} isPadding>
            <div className={styles.modalBody}>
                <div className={styles.headline}>
                    <FormattedMessage id="copyLink.IOS" />
                </div>
                <Separator height="20px" />
                <div className={styles.Message}>
                    <FormattedMessage id="copyLink.IOS.title" />
                </div>
                <Separator height="20px" />
                <div className={styles.footer}>
                    <CopyToClipboard
                        text={shareLink}
                        onCopy={() => triggerNotification({ message: formatMessage({ id: "Copy.clipboard.success" }), type: "info" })}
                    >
                        <Button onClick={onClose}
                            variant="primary"
                            data-testid='copy-share-link-btn'>
                          <span className={styles.footerMessage}>  <FormattedMessage id="footer.copy.link" /> </span>
                        </Button>
                    </CopyToClipboard>
                </div>
            </div>
        </Modal>
    );
};
