import { Main } from "components/Main";
import { useIntl } from "react-intl";
import { useSelector } from "redux/hooks";
import { useEffectOnce } from "react-use";

import {
  VersatilityIcon,
  CustomizationIcon,
  EnhancedPerformanceIcon,
  FutureProofingIcon,
  InnovationIcon,
  SpecializedSolutionsIcon,
  BrowseModelsIcon,
  SelectModelIcon,
  ApplyTheModelIcon,
} from "pages/Features/AccessToDiverseModels/icons";

import {
  Hero,
  IImage,
  PerksOfFeature,
  IFeatureItem,
  HowToUseTheFeature,
  IFeature,
  Faqs,
  IFaq,
  iconSizeClassName,
  iconClassName,
} from "pages/Features/components";

const heroImages: IImage[] = [
  {
    src: "/feature/model/image1.png",
    alt: "",
  },
  {
    src: "/feature/model/image2.png",
    alt: "",
  },
  {
    src: "/feature/model/image3.png",
    alt: "",
  },
  {
    src: "/feature/model/image4.png",
    alt: "",
  },
  {
    src: "/feature/model/image5.png",
    alt: "",
  },
  {
    src: "/feature/model/image6.png",
    alt: "",
  },
];

const featureItems: IFeatureItem[] = [
  {
    icon: <VersatilityIcon className={iconSizeClassName} />,
    title: "feature.model.versatilityTitle",
    content: "feature.model.versatilityContent",
  },
  {
    icon: <SpecializedSolutionsIcon className={iconSizeClassName} />,
    title: "feature.model.specializedSolutionsTitle",
    content: "feature.model.specializedSolutionsContent",
  },
  {
    icon: <InnovationIcon className={iconSizeClassName} />,
    title: "feature.model.innovationTitle",
    content: "feature.model.innovationContent",
  },
  {
    icon: <CustomizationIcon className={iconSizeClassName} />,
    title: "feature.model.customizationTitle",
    content: "feature.model.customizationContent",
  },
  {
    icon: <EnhancedPerformanceIcon className={iconSizeClassName} />,
    title: "feature.model.enhancedPerformanceTitle",
    content: "feature.model.enhancedPerformanceContent",
  },
  {
    icon: <FutureProofingIcon className={iconSizeClassName} />,
    title: "feature.model.futureProofingTitle",
    content: "feature.model.futureProofingContent",
  },
];

const features: IFeature[] = [
  {
    icon: <BrowseModelsIcon className={iconClassName} />,
    title: "feature.model.browseModelsTitle",
    content: "feature.model.browseModelsContent",
  },
  {
    icon: <SelectModelIcon className={iconClassName} />,
    title: "feature.model.selectModelTitle",
    content: "feature.model.selectModelContent",
  },
  {
    icon: <ApplyTheModelIcon className={iconClassName} />,
    title: "feature.model.applyTheModelTitle",
    content: "feature.model.applyTheModelContent",
  },
];

const faqs: IFaq[] = [
  {
    title: "feature.model.faq1Title",
    content: "feature.model.faq1Content",
  },
  {
    title: "feature.model.faq2Title",
    content: "feature.model.faq2Content",
  },
  {
    title: "feature.model.faq3Title",
    content: "feature.model.faq3Content",
  },
];

export const AccessToDiverseModelsPage = () => {
  const { formatMessage } = useIntl();
    const themeMode = useSelector((state) => state.authReducer.theme);

    useEffectOnce(() => {
      window.scrollTo(0, 0);
    });

  return (
    <Main isHasBgOnFooter activeSection="accessToDiverseModels">
      <Hero
        themeMode={themeMode}
        images={heroImages}
        title={formatMessage({ id: "feature.model.title" })}
        content={formatMessage({ id: "feature.model.content" })}
      />
      <PerksOfFeature features={featureItems} themeMode={themeMode} />
      <HowToUseTheFeature features={features} themeMode={themeMode} />
      <Faqs faqs={faqs} themeMode={themeMode} />
    </Main>
  );
};
