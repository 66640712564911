import { Main } from "components/Main";
import { useIntl } from "react-intl";
import { useSelector } from "redux/hooks";
import { useEffectOnce } from "react-use";

import {
  ContextualDiscussionsIcon,
  RealTimeCollabortationIcon,
  DocumentVersioningIcon,
  CentralizedCommunicationIcon,
  IntegratedFeedbackIcon,
  EnhancedAccessibilityIcon,
  UploadDocuementIcon,
  InitiateDiscussionIcon,
  InteractInRealTimeIcon,
} from "pages/Features/DocumentBasedChat/icons";

import {
  Hero,
  IImage,
  PerksOfFeature,
  IFeatureItem,
  HowToUseTheFeature,
  IFeature,
  Faqs,
  IFaq,
  iconSizeClassName,
  iconClassName,
} from "pages/Features/components";

const heroImages: IImage[] = [
  {
    src: "/feature/document/image1.png",
    alt: "",
  },
  {
    src: "/feature/document/image2.png",
    alt: "",
  },
  {
    src: "/feature/document/image3.png",
    alt: "",
  },
  {
    src: "/feature/document/image4.png",
    alt: "",
  },
  {
    src: "/feature/document/image5.png",
    alt: "",
  },
  {
    src: "/feature/document/image6.png",
    alt: "",
  },
];


const featureItems: IFeatureItem[] = [
  {
    icon: <ContextualDiscussionsIcon className={iconSizeClassName} />,
    title: "feature.document.contextualDiscussionsTitle",
    content: "feature.document.contextualDiscussionsContent",
  },
  {
    icon: <RealTimeCollabortationIcon className={iconSizeClassName} />,
    title: "feature.document.realTimeCollaborationTitle",
    content: "feature.document.realTimeCollaborationContent",
  },
  {
    icon: <DocumentVersioningIcon className={iconSizeClassName} />,
    title: "feature.document.documentVersioningTitle",
    content: "feature.document.documentVersioningContent",
  },
  {
    icon: <CentralizedCommunicationIcon className={iconSizeClassName} />,
    title: "feature.document.centralizedCommunicationTitle",
    content: "feature.document.centralizedCommunicationContent",
  },
  {
    icon: <IntegratedFeedbackIcon className={iconSizeClassName} />,
    title: "feature.document.integratedFeedbackTitle",
    content: "feature.document.integratedFeedbackContent",
  },
  {
    icon: <EnhancedAccessibilityIcon className={iconSizeClassName} />,
    title: "feature.document.enhancedAccessibilityTitle",
    content: "feature.document.enhancedAccessibilityContent",
  },
];

const features: IFeature[] = [
  {
    icon: <UploadDocuementIcon className={iconClassName} />,
    title: "feature.document.uploadDocumentTitle",
    content: "feature.document.uploadDocumentContent",
  },
  {
    icon: <InitiateDiscussionIcon className={iconClassName} />,
    title: "feature.document.initiateDiscussionTitle",
    content: "feature.document.initiateDiscussionContent",
  },
  {
    icon: <InteractInRealTimeIcon className={iconClassName} />,
    title: "feature.document.interactInRealTimeTitle",
    content: "feature.document.interactInRealTimeContent",
  },
];

const faqs: IFaq[] = [
  {
    title: "feature.document.faq1Title",
    content: "feature.document.faq1Content",
  },
  {
    title: "feature.document.faq2Title",
    content: "feature.document.faq2Content",
  },
  {
    title: "feature.document.faq3Title",
    content: "feature.document.faq3Content",
  },
];

export const DocumentBasedChatPage = () => {
  const { formatMessage } = useIntl();
    const themeMode = useSelector((state) => state.authReducer.theme);

    useEffectOnce(() => {
      window.scrollTo(0, 0);
    });

  return (
    <Main isHasBgOnFooter activeSection="documentBasedChat">
      <Hero
        themeMode={themeMode}
        images={heroImages}
        title={formatMessage({ id: "feature.document.title" })}
        content={formatMessage({ id: "feature.document.content" })}
      />
      <PerksOfFeature features={featureItems} themeMode={themeMode} />
      <HowToUseTheFeature features={features} themeMode={themeMode} />
      <Faqs faqs={faqs} themeMode={themeMode} />
    </Main>
  );
};
