import { useState, ChangeEvent, Dispatch, SetStateAction, FormEvent, useEffect } from "react";
import { Modal, Spinner } from "components";
import classNames from "classnames";
import styles from './docUpload.module.scss';
import { useSelector } from "redux/hooks";
import { getFileSize, getImageSize, getVideoDuration, validateFileType } from "utils/functions";
import useRouter from "hooks/useRouter";

import { FormattedMessage, useIntl } from "react-intl";
import validUrl from "valid-url";
import { setChatModel, IChatModel, ChatType } from "redux/actions";
import { IFileErrorState } from "pages/ChatPage/pages/newChat/NewChat";
import { useWindowSize } from "hooks/useWindowSize";
import { documentMaxCountReached, videoMaxCountReached } from "utils/chat";

interface IProp {
    setISUploadURL?: Dispatch<SetStateAction<boolean>>;
    setErrorModal: Dispatch<SetStateAction<IFileErrorState>>;
    setUploadUrl?: Dispatch<SetStateAction<boolean>>;
    message?: string;
    resetMessage?: () => void;
    url?: string;
    setURL?: Dispatch<SetStateAction<string>>;
    onSendMessage?: (question: string, chatModels?: IChatModel, regenerate?: boolean, images?: string[], filePath?: string[]) => void;
    setMessageId?: Dispatch<SetStateAction<string>>;
    changeModel?: boolean;
    setChangeModel?: Dispatch<SetStateAction<boolean>>;
}

export const UploadURL = ({ setISUploadURL, url,
    setURL,
    setErrorModal, setUploadUrl, message,
    resetMessage,
    onSendMessage,
    setMessageId,
    changeModel,
    setChangeModel,
}: IProp) => {

    const AllowedFileSize = 100;
    const FreePlanAllowedFileSize = 10;
    const StandardPlanAllowedFileSize = 50;

    const { pathname } = useRouter();
    const { chatModels } = useSelector((state) => state.chatModelsReducer);
    const { theme, gptModel, userDetail } = useSelector((state) => state.authReducer);
    const { newMessages, messages } = useSelector((state) => state.chatReducer);
    const chatModel = useSelector((state) => state.authReducer.gptModel);
    const { formatMessage } = useIntl();
    const currentPlan = useSelector(
        (state) => state.planSubscriptionReducer.activePlan
    );

    const planName = userDetail?.user.activeSubscription.name.toLowerCase();
    const { width } = useWindowSize();

    const chatId = pathname.split("/")[3];
    const chatHistory = pathname.includes('chat/new');

    const getConditionalAllowedFileTypes = () => {
        const baseFileTypes = [
            "pdf",
            "txt",
            "ppt",
            "pptx",
            // "doc",
            "docx",
            "csv",
            "xls",
            "xlsx",
            "eml",
            "srt",
            "png",
            "jpeg",
            "webp",
            "gif",
            "jpg",
            "mp4",
            "mpeg",
            "mpg",
            "webm",
            "wmv",
            "3gpp",
            // "3gp"
        ];
        return gptModel?.name.includes("Claude") ? baseFileTypes : [...baseFileTypes, "jpg"];
    };

    const [isURLLoading, setIsURLLoading] = useState<boolean>(false);

    const getFileURL = (event: ChangeEvent<HTMLInputElement>) => {
        setURL!(event.target.value);
        const urlExtension = event.target.value?.split('.').pop()?.toLowerCase() || '';
        const imgURL = ["png", "jpg", "jpeg", "webp", "gif"];
        const videoURL = ["mp4", "mpeg", "mpg", "webm", "wmv", "3gpp"];
        
        const videoSupportedModel = chatModels
        .filter(model => model.type.includes(ChatType.video))
        .map(model => model.name);

        if (changeModel && videoURL.includes(urlExtension)) {
            const imageChatModel = localStorage.getItem('videoChatGptModel');
            if (imageChatModel) {
                localStorage.setItem('GptModel', imageChatModel);
                const IChatModel = JSON.parse(imageChatModel);
                setChatModel(IChatModel);
                setChangeModel?.(false);
            }
        }

        if (changeModel && imgURL.includes(urlExtension)) {
            const imageChatModel = localStorage.getItem('imageChatGptModel');
            if (imageChatModel) {
                localStorage.setItem('GptModel', imageChatModel);
                const IChatModel = JSON.parse(imageChatModel);
                setChatModel(IChatModel);
                setChangeModel?.(false);
            }
        }

        if (imgURL.includes(urlExtension) && (gptModel?.name === "GPT-3.5-16K" || gptModel?.name === "GPT-4o mini")) {
            const imageChatModel = localStorage.getItem('imageChatGptModel');
            let selectedModel = imageChatModel ? JSON.parse(imageChatModel) : chatModels[1];
            if (imageChatModel) {
                localStorage.setItem('GptModel', imageChatModel);
            }
            else {
                localStorage.setItem('imageChatGptModel', JSON.stringify(chatModels[1]));
                localStorage.setItem('GptModel', JSON.stringify(chatModels[1]));
            }
            setChatModel(selectedModel);
        }
     
        if (!videoSupportedModel.includes(gptModel?.name ?? '') && videoURL.includes(urlExtension)
        ) {
          const imageChatModel = localStorage.getItem('videoChatGptModel');
          const model= JSON.parse(imageChatModel?? "");
          if (!videoSupportedModel.includes(model?.name ?? '')) {
            const selectedModel = chatModels[1];
            localStorage.setItem('videoChatGptModel', JSON.stringify(selectedModel));
            localStorage.setItem('GptModel', JSON.stringify(selectedModel));
            setChatModel(selectedModel);
          }
          else {
            const selectedModel = imageChatModel ? JSON.parse(imageChatModel) : chatModels[1];
            localStorage.setItem('videoChatGptModel', JSON.stringify(selectedModel));
            localStorage.setItem('GptModel', JSON.stringify(selectedModel));
            setChatModel(selectedModel);
          }
        }
    }

    useEffect(() => {
        setURL!('')
    }, [])

    const onSubmit = async (e: FormEvent<EventTarget | HTMLFormElement>) => {
        e.preventDefault();

        const urlExtension = url?.split('.').pop()?.toLowerCase() || '';
        const imgURL = ["png", "jpg", "jpeg", "webp", "gif"];
        const docURL = ["pdf", "txt", "ppt", "pptx", "docx", "csv", "xls", "xlsx", "eml", "srt",]
        const videoURL = ["mp4", "mpeg", "mpg", "webm", "wmv", "3gpp"];

        if (docURL.includes(urlExtension)) {
            if (documentMaxCountReached(currentPlan, userDetail)) {
                setMessageId!("documentChat.plan.max_count");
                setISUploadURL!(false);
                setURL!('');
                return;
            }
        }
        if (videoURL.includes(urlExtension)) {
            if (videoMaxCountReached(currentPlan, userDetail)) {
                setMessageId!("videoChat.plan.max_count");
                setMessageId!("documentChat.plan.max_count");
                setISUploadURL!(false);
                setURL!('');
                return;
            }
        }

        if (((newMessages.length > 0 &&
            newMessages[0]?.chatType === "video"
            && chatHistory) || (messages[0]?.chatType === 'video' && chatId)) && (docURL.includes(urlExtension) || imgURL.includes(urlExtension))) {
            setURL!('');
            setErrorModal({
                message: formatMessage({ id: "upload.file.validation.video" }),
                show: true,
            });
            resetMessage!();
            setISUploadURL!(false);
            return false;
        } else if (((newMessages.length > 0 &&
            newMessages[0]?.chatType === "image_chat"
            && chatHistory) || (messages[0]?.chatType === 'image_chat' && chatId)) && (docURL.includes(urlExtension) || videoURL.includes(urlExtension))) {
            setURL!('');
            setErrorModal({
                message: formatMessage({ id: "upload.file.validation.docURL" }),
                show: true,
            });
            resetMessage!();
            setISUploadURL!(false);
            return false;
        }
        else if (((newMessages.length > 0 &&
            newMessages[0]?.chatType === "document"
            && chatHistory) || (messages[0]?.chatType === 'document' && chatId)) && (imgURL.includes(urlExtension) || videoURL.includes(urlExtension))) {
            setURL!('');
            setErrorModal({
                message: formatMessage({ id: "upload.file.validation.imgURL" }),
                show: true,
            });
            resetMessage!();
            setISUploadURL!(false);
            return false;
        }
        else if (await validateURL()) {
            setIsURLLoading(true);
            setUploadUrl!(true);
            const urlExtension = url?.split('.').pop()?.toLowerCase() || '';
            const imgURL = ["png", "jpg", "jpeg", "webp", "gif"]

            if (imgURL.includes(urlExtension)) {
                onSendMessage!(message ? message : "", chatModel, false, url ? [url] : [], [])
            }
            else {
                onSendMessage!(message ? message : "", chatModel, false, [], url ? [url] : [])
            }
            setTimeout(() => {
                setURL!('')
                setISUploadURL!(false)
                resetMessage!()
                setUploadUrl!(false);
            }, 50)
        }
    };

    const handleStateUpdate =() => {
        setURL!('');
        resetMessage!();
        setIsURLLoading(false);
        setISUploadURL!(false);
        return ;
    }

    const validateURL = async (): Promise<boolean> => {
        if (validUrl.isHttpsUri(url ? url : '')) {
            setIsURLLoading(true);
            const conditionalAllowedFileTypes = getConditionalAllowedFileTypes();
            const urlExtension = url?.split('.').pop()?.toLowerCase() || '';
            const imgURL = ["png", "jpg", "jpeg", "webp", "gif"];
            const videoURL = ["mp4", "mpeg", "mpg", "webm", "wmv", "3gpp"];

            // New validation for multiple URLs or text after a comma
            const commaIndex = url?.indexOf(',') ?? -1; // Default to -1 if url is undefined
            if (commaIndex !== -1) {
                const textAfterComma = url?.slice(commaIndex + 1).trim();
                if (textAfterComma) {
                    setErrorModal({
                        message: formatMessage({ id: "upload.url.validation" }),
                        show: true,
                    });
                    handleStateUpdate();
                    return false;
                }
            }
            if ((validateFileType({ url: url, types: conditionalAllowedFileTypes }))) {

                if (imgURL.includes(urlExtension)) {
                    setIsURLLoading(true);
                    const imageSize = await getImageSize(url ? url : '');
                    const allowedImgSize = gptModel?.name.includes("GPT-4") ? 15 : 5;
                    const maxSizeInBytes = gptModel?.name.includes("GPT-4") ? 15 * 1024 * 1024 : 5 * 1024 * 1024; // 5 MB

                    if (imageSize > maxSizeInBytes) {
                        setErrorModal({
                            message: formatMessage(
                                { id: 'ImgChat.file.fileSizeImage' },
                                { size: allowedImgSize }
                            ),
                            show: true,
                        });
                        handleStateUpdate();
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else {
                    const FileSize = await getFileSize(url ? url : '');
                    
                    if(planName==='free' && FileSize > FreePlanAllowedFileSize){
                        setMessageId!(`documentChat.file.${planName}.plan.fileSize`);
                        handleStateUpdate();
                        return false;
                    }
                    else if (planName === 'standard' && FileSize > StandardPlanAllowedFileSize){
                        setMessageId!(`documentChat.file.${planName}.plan.fileSize`);
                        handleStateUpdate();
                        return false;
                    }
                    else if (FileSize > AllowedFileSize){
                        // setMessageId!("documentChat.file.fileSize");
                        setErrorModal({
                            message: "documentChat.file.fileSize",
                            show: true,
                        });
                        handleStateUpdate();
                        return false;
                    }

                    if (videoURL.includes(urlExtension)) {
                        try {
                            const videoDuration = await getVideoDuration(url ?? ""); // You'll need a function to get the duration
                            const maxDurationInSeconds = 30 * 60; // 30 minutes in seconds

                            if (videoDuration > maxDurationInSeconds) {
                                setErrorModal({
                                    message: formatMessage({ id: 'validate.video.duration' }),
                                    show: true,
                                });
                                handleStateUpdate();
                                return false;
                            } else {
                                return true;
                            }
                        } catch (error) {
                            console.error("Error fetching video duration", error);
                            // setErrorModal({
                            //     message: "validate.video.duration",
                            //     show: true,
                            // });
                            handleStateUpdate();
                            return true;
                        }
                    }
                    else {
                        return true;
                    }
                }
            } else {
                setErrorModal({
                    message: "documentChat.url.fileFormat",
                    show: true,
                });
                handleStateUpdate();
                return false;
            }
        } else {
            setErrorModal({ message: "documentChat.url.validation", show: true });
            handleStateUpdate();
            return false;
        }
    };

    return (
        <>
            <Modal size='md' modalPrompt={width <= 576} onClose={() => {
                if (!isURLLoading) {
                    setISUploadURL!(false);
                    setURL!('');
                }
            }}>
                <div className='py-[20px] px-[30px]'>
                    <div className={styles.urlHeading}>
                        <FormattedMessage id="doc.upload.enter.url" />
                    </div>
                    <form data-testid='document-form' className={styles.form}
                        onSubmit={onSubmit}
                    >
                        <input
                            className={classNames(styles.input, {
                                [styles.light]: theme === "light",
                                [styles.dark]: theme === "dark",
                            })}
                            autoFocus
                            placeholder={formatMessage({
                                id: "doc.url.palaceholder",
                            })}
                            value={url}
                            onChange={getFileURL}
                            data-testid='document-upload-input'
                        />
                        <button
                            data-testid='document-submit-btn'
                            className={classNames({ [styles.isLoading]: isURLLoading })}
                            type="submit"
                        >
                            {isURLLoading ? (
                                <Spinner extraSmall isWhite={true} />
                            ) : (
                                <FormattedMessage id="documentChat.url.submitBtn" />
                            )}
                        </button>
                    </form>
                    <div className={classNames(styles.supportedFilesContainer, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                    })}>
                        <span className={styles.SupportedHeader}> <FormattedMessage id="SupportedFiles.url" /></span>
                        <span className={styles.supportedFiles}>pdf, txt, ppt, pptx, docx, csv, xls, xlsx, eml, srt, png, jpg, jpeg, webp, gif, mp4, mpeg, mpg, webm, wmv, 3gpp, 3gp</span>
                    </div>
                </div>
            </Modal>
        </>
    )
}