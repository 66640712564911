import { FormattedMessage } from "react-intl";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import classNames from "classnames";
import styles from "./Terms.module.scss";
import { useEffectOnce } from "react-use";

import { Main } from "components/Main";
import { markdownTermsContent } from "./termsMd";

import { useSelector } from "redux/hooks";

const Terms = () => {
  const theme = useSelector((state) => state.authReducer.theme);

  const linkRenderer = ({ href, children }: any) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );

  const renderers = {
    a: linkRenderer,
  };

  useEffectOnce(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Main>
      <h1
        className={classNames(styles.title, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
      >
        <FormattedMessage id="landing.terms.title" />
      </h1>
      <div
        className={classNames(styles.content, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
      >
        <ReactMarkdown remarkPlugins={[remarkGfm]} components={renderers}>
          {markdownTermsContent}
        </ReactMarkdown>
      </div>
    </Main>
  );
};

export default Terms;
