export const NewUploadIcon = () =>
(
    <svg xmlns="http://www.w3.org/2000/svg" width="337" height="125" viewBox="0 0 337 125" fill="none">
        <path d="M67.7458 47.7508C62.1502 49.641 56.2685 46.7296 54.3783 41.134L49.6528 27.145C48.8427 24.7469 46.7039 23.6883 44.3058 24.4983L12.3309 35.2995C3.53787 38.2699 -1.2262 47.8945 1.74413 56.6875L20.6462 112.643C23.6166 121.437 33.2412 126.201 42.0342 123.23L89.9965 107.028C98.7895 104.058 103.554 94.4336 100.583 85.6405L87.0818 45.672C86.2717 43.2738 84.1329 42.2152 81.7348 43.0253L67.7458 47.7508ZM65.9081 88.448L41.927 96.5489C39.5289 97.359 37.3901 96.3003 36.58 93.9022C35.77 91.5041 36.8286 89.3653 39.2267 88.5552L63.2079 80.4543C65.606 79.6442 67.7448 80.7029 68.5549 83.101C69.3649 85.4991 68.3063 87.6379 65.9081 88.448ZM68.5013 69.7603L36.5264 80.5615C34.1283 81.3716 31.9895 80.3129 31.1794 77.9148C30.3694 75.5167 31.428 73.3778 33.8261 72.5678L65.801 61.7666C68.1991 60.9565 70.3379 62.0152 71.148 64.4133C71.958 66.8114 70.8994 68.9502 68.5013 69.7603Z" fill="#7A95F3" />
        <path d="M233.108 21.0644C233.057 9.95397 223.925 0.905513 212.814 0.95676L132.011 1.32946C120.9 1.3807 111.852 10.513 111.903 21.6235L112.183 82.226C112.234 93.3365 121.366 102.385 132.477 102.334L213.28 101.961C224.391 101.91 233.439 92.7774 233.388 81.667L233.108 21.0644ZM142.204 21.4837C147.76 21.4581 152.326 25.9823 152.352 31.5376C152.377 37.0928 147.853 41.6589 142.298 41.6846C136.742 41.7102 132.176 37.186 132.151 31.6307C132.125 26.0755 136.649 21.5093 142.204 21.4837ZM223.287 81.7136C223.313 87.2688 218.789 91.835 213.234 91.8606L134.45 92.224C129.905 92.2449 127.859 86.699 130.876 83.6549L148.972 65.3903C150.983 63.3609 154.013 63.3469 156.043 65.3577L159.087 68.3738C161.116 70.3846 164.146 70.3706 166.157 68.3412L199.335 34.8561C201.346 32.8267 204.376 32.8127 206.405 34.8235L221.626 49.9042C222.64 50.9096 223.15 51.9173 223.157 53.4324L223.287 81.7136Z" fill="url(#paint0_linear_22386_7105)" />
        <path d="M322.901 30.98L268.514 23.9955C264.393 23.4663 260.231 24.5959 256.942 27.1357C253.654 29.6756 251.509 33.4178 250.98 37.539L243.995 91.9257C243.466 96.0469 244.596 100.21 247.136 103.498C249.675 106.786 253.418 108.931 257.539 109.46L311.926 116.445C316.047 116.974 320.209 115.844 323.498 113.305C326.786 110.765 328.931 107.023 329.46 102.901L336.445 48.5146C336.974 44.3934 335.844 40.2308 333.304 36.9424C330.764 33.654 327.022 31.5093 322.901 30.98ZM280.828 51.1061C280.953 50.1367 281.323 49.2152 281.904 48.4293C282.485 47.6433 283.257 47.0188 284.148 46.6153C285.038 46.2117 286.016 46.0424 286.991 46.1234C287.965 46.2044 288.902 46.533 289.713 47.0781L300.877 54.5733C301.762 55.1663 302.463 55.994 302.904 56.9636C303.344 57.9332 303.505 59.0062 303.37 60.0624C303.234 61.1185 302.807 62.116 302.136 62.9429C301.465 63.7697 300.577 64.3933 299.571 64.7436L286.875 69.1746C285.953 69.497 284.963 69.5782 284 69.4105C283.037 69.2427 282.133 68.8316 281.374 68.2162C280.614 67.6008 280.025 66.8015 279.661 65.8942C279.298 64.9869 279.172 64.0018 279.297 63.0323L280.828 51.1061ZM314.42 97.021L260.033 90.0365C259.003 89.9042 258.067 89.368 257.432 88.5459C256.797 87.7238 256.515 86.6832 256.647 85.6529C256.78 84.6226 257.316 83.687 258.138 83.052C258.96 82.4171 260.001 82.1347 261.031 82.267L315.418 89.2515C316.448 89.3838 317.384 89.92 318.019 90.7421C318.654 91.5641 318.936 92.6048 318.804 93.6351C318.671 94.6654 318.135 95.601 317.313 96.2359C316.491 96.8709 315.45 97.1533 314.42 97.021Z" fill="#7A95F3" />
        <defs>
            <linearGradient id="paint0_linear_22386_7105" x1="168.716" y1="0.956543" x2="168.716" y2="124.114" gradientUnits="userSpaceOnUse">
                <stop stop-color="#7C4DFF" />
                <stop offset="1" stop-color="#4693E8" />
            </linearGradient>
        </defs>
    </svg>
)