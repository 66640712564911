import {  getAllTeams, getCreditLimits, ITeam, selectTeam } from "redux/actions";

export const SwitchTeam = async () => {
    try {
        const response = await getAllTeams() as ITeam[];
        const team_id = response[0]?.id;

        if (!team_id) {
            throw new Error("No teams available");
        }

        await selectTeam({ team_id });

        try {
            await getCreditLimits();
            // Optionally call setTeam(team_id) here if needed
        } catch (err) {
            console.error(err,'Failed to retrieve credit limits');
        }
    } catch (err) {
        console.error(err,'Failed to switch teams');
    }
};

