export const VideoPlayIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
            <g clip-path="url(#clip0_22133_37192)">
                <path d="M23.0007 42.1666C12.4149 42.1666 3.83398 33.5857 3.83398 22.9999C3.83398 12.4142 12.4149 3.83325 23.0007 3.83325C33.5864 3.83325 42.1673 12.4142 42.1673 22.9999C42.1673 33.5857 33.5864 42.1666 23.0007 42.1666ZM20.3595 16.1287C20.2441 16.0517 20.1101 16.0075 19.9716 16.0007C19.8331 15.9939 19.6953 16.0248 19.573 16.0901C19.4506 16.1554 19.3483 16.2526 19.2769 16.3715C19.2054 16.4903 19.1676 16.6263 19.1673 16.765V29.2348C19.1676 29.3735 19.2054 29.5095 19.2769 29.6284C19.3483 29.7472 19.4506 29.8445 19.573 29.9098C19.6953 29.9751 19.8331 30.006 19.9716 29.9992C20.1101 29.9924 20.2441 29.9481 20.3595 29.8712L29.7109 23.6382C29.8161 23.5682 29.9023 23.4733 29.962 23.362C30.0216 23.2506 30.0528 23.1262 30.0528 22.9999C30.0528 22.8736 30.0216 22.7492 29.962 22.6379C29.9023 22.5265 29.8161 22.4317 29.7109 22.3617L20.3595 16.1287Z" fill="#D4D4D4" />
            </g>
            <defs>
                <clipPath id="clip0_22133_37192">
                    <rect width="46" height="46" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export const VideoPauseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
            <g clip-path="url(#clip0_22133_37193)">
                <path d="M23 42.1666C12.4142 42.1666 3.83325 33.5857 3.83325 22.9999C3.83325 12.4142 12.4142 3.83325 23 3.83325C33.5858 3.83325 42.1667 12.4142 42.1667 22.9999C42.1667 33.5857 33.5858 42.1666 23 42.1666ZM18.4166 29.1666C18.6991 29.1666 18.9674 29.053 19.1593 28.8612C19.3511 28.6693 19.4648 28.401 19.4648 28.1185V17.8812C19.4648 17.5987 19.3511 17.3305 19.1593 17.1386C18.9674 16.9468 18.6991 16.8333 18.4166 16.8333C18.1341 16.8333 17.8658 16.9468 17.674 17.1386C17.4821 17.3305 17.3685 17.5987 17.3685 17.8812V28.1185C17.3685 28.401 17.4821 28.6693 17.674 28.8612C17.8658 29.053 18.1341 29.1666 18.4166 29.1666ZM27.5833 29.1666C27.8658 29.1666 28.1341 29.053 28.326 28.8612C28.5178 28.6693 28.6314 28.401 28.6314 28.1185V17.8812C28.6314 17.5987 28.5178 17.3305 28.326 17.1386C28.1341 16.9468 27.8658 16.8333 27.5833 16.8333C27.3008 16.8333 27.0325 16.9468 26.8407 17.1386C26.6488 17.3305 26.5352 17.5987 26.5352 17.8812V28.1185C26.5352 28.401 26.6488 28.6693 26.8407 28.8612C27.0325 29.053 27.3008 29.1666 27.5833 29.1666Z" fill="#D4D4D4" />
            </g>
            <defs>
                <clipPath id="clip0_22133_37193">
                    <rect width="46" height="46" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}