import { ReactNode, Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import classNames from "classnames";
import styles from "./PricingTable.module.scss";
import styled from "styled-components";

import { TickIcon } from "../icons";
import {
  PriceLoginAccessIcon,
  PriceCountryIcon,
  PriceDowntimeIcon,
  PriceQueryTimeIcon,
  PriceTeamMembersIcon,
  PriceHistoryRelentionIcon,
  ModalIcon,
  ImageGenerationIcon,
  PriceFileSizeIcon,
  PricePageLimitIcon,
  PriceMultipleDocumentsIcon,
} from "pages/LandingPage/components/Icons";

import { MobilePricing } from "pages/LandingPage/sections/MobilePricing";
import CustomButton from "components/Button";
import { Separator } from "components";

import { EPlanDuration, IPlan, IUserDetail } from "redux/actions";
import { useSelector } from "redux/hooks";

interface IProps {
  userDetail?: IUserDetail;
  requiredPlan?: IPlan;
  upgradePlanLoading?: boolean;
  onUpgradePlan?: (selectedPlan: IPlan) => void;
  plan: IPlan[];
  duration?: EPlanDuration;
  toggleCancelPaymentModal?: () => void;
}

export interface IPlanTableRow {
  planName: ReactNode;
  free?: boolean;
  freeText?: string | ReactNode;
  standard?: boolean;
  standardText?: string | ReactNode;
  professional?: boolean;
  professionalText?: string | ReactNode;
  team?: boolean;
  teamText?: string | ReactNode;
  business?: boolean;
  businessText: string | ReactNode;
  icon?: ReactNode;
  isShowLangingPage?: boolean;
  [key: string]: any;
}

export const planTable: IPlanTableRow[] = [
  {
    planName: <FormattedMessage id="landing.price.table.first.td1" />,
    icon: <PriceLoginAccessIcon />,
    free: true,
    freeText: "",
    standard: true,
    standardText: "",
    professional: true,
    professionalText: "",
    team: true,
    teamText: "",
    business: true,
    businessText: "",
    isShowLangingPage: true,
  },
  {
    planName: <FormattedMessage id="landing.price.table.first.td2" />,
    icon: <PriceCountryIcon />,
    free: true,
    freeText: "",
    standard: true,
    standardText: "",
    professional: true,
    professionalText: "",
    team: true,
    teamText: "",
    business: true,
    businessText: "",
    isShowLangingPage: true,
  },
  {
    planName: <FormattedMessage id="landing.price.table.first.td3" />,
    icon: <PriceDowntimeIcon />,
    free: false,
    freeText: <FormattedMessage id="landing.price.downtTime.free" />,
    standard: false,
    standardText: <FormattedMessage id="landing.price.downtTime.noFree" />,
    professional: false,
    professionalText: <FormattedMessage id="landing.price.downtTime.noFree" />,
    team: false,
    teamText: <FormattedMessage id="landing.price.downtTime.noFree" />,
    business: false,
    businessText: <FormattedMessage id="landing.price.downtTime.noFree" />,
    isShowLangingPage: true,
  },
  {
    planName: <FormattedMessage id="landing.price.table.first.td4" />,
    icon: <PriceQueryTimeIcon />,
    free: false,
    freeText: <FormattedMessage id="landing.price.queryTime.free" />,
    standard: false,
    standardText: <FormattedMessage id="landing.price.queryTime.noFree" />,
    professional: false,
    professionalText: <FormattedMessage id="landing.price.queryTime.noFree" />,
    team: false,
    teamText: <FormattedMessage id="landing.price.queryTime.noFree" />,
    business: false,
    businessText: <FormattedMessage id="landing.price.queryTime.noFree" />,
    isShowLangingPage: true,
  },
  {
    planName: <FormattedMessage id="landing.price.table.first.td5" />,
    icon: <PriceTeamMembersIcon />,
    free: false,
    freeText: "-",
    standard: false,
    standardText: "-",
    professional: false,
    professionalText:2,
    team: false,
    teamText: 5,
    business: false,
    businessText: <FormattedMessage id="landing.price.teammembers.unlimited" />,
    isShowLangingPage: true,
  },
  {
    planName: <FormattedMessage id="landing.price.table.first.td6" />,
    icon: <PriceHistoryRelentionIcon />,
    free: false,
    freeText: "-",
    standard: false,
    standardText: "-",
    professional: false,
    professionalText: (
      <FormattedMessage
        id="landing.price.historyRetention.noFree"
        values={{ number: 7 }}
      />
    ),
    team: false,
    teamText: (
      <FormattedMessage
        id="landing.price.historyRetention.noFree"
        values={{ number: 30 }}
      />
    ),
    business: false,
    businessText: (
      <FormattedMessage
        id="landing.price.historyRetention.noFree"
        values={{ number: 90 }}
      />
    ),
    isShowLangingPage: true,
  },
  {
    planName: <FormattedMessage id="setting.plan.td7.1" />,
    icon: <ModalIcon />,
    free: false,
    freeText: <FormattedMessage id="setting.plan.td9.value" />,
    standard: false,
    standardText: <FormattedMessage id="setting.plan.td9.value" />,
    professional: false,
    professionalText: <FormattedMessage id="setting.plan.td9.value" />,
    team: false,
    teamText: <FormattedMessage id="setting.plan.td9.value" />,
    business: false,
    businessText: <FormattedMessage id="setting.plan.td9.value" />,
    isShowLangingPage: true,
  },
  {
    planName: <FormattedMessage id="setting.plan.td9" />,
    icon: <ImageGenerationIcon />,
    free: false,
    freeText: <FormattedMessage id="setting.plan.td9.value" />,
    standard: false,
    standardText: <FormattedMessage id="setting.plan.td9.value" />,
    professional: false,
    professionalText: <FormattedMessage id="setting.plan.td9.value" />,
    team: false,
    teamText: <FormattedMessage id="setting.plan.td9.value" />,
    business: false,
    businessText: <FormattedMessage id="setting.plan.td9.value" />,
    isShowLangingPage: true,
  },
  {
    planName: <FormattedMessage id="setting.plan.td10" />,
    icon: <PriceFileSizeIcon />,
    free: false,
    freeText: "10 mb",
    standard: false,
    standardText: "50 mb",
    professional: false,
    professionalText: "100 mb",
    team: false,
    teamText: "100 mb",
    business: false,
    businessText: "100 mb",
    isShowLangingPage: true,
  },
  {
    planName: <FormattedMessage id="setting.plan.td11" />,
    icon: <PricePageLimitIcon />,
    free: false,
    freeText: (
      <FormattedMessage id="setting.plan.td11.value" values={{ page: 100 }} />
    ),
    standard: false,
    standardText: (
      <FormattedMessage id="setting.plan.td11.value" values={{ page: 500 }} />
    ),
    professional: false,
    professionalText: (
      <FormattedMessage id="setting.plan.td11.value" values={{ page: 2500 }} />
    ),
    team: false,
    teamText: (
      <FormattedMessage id="setting.plan.td11.value" values={{ page: 2500 }} />
    ),
    business: false,
    businessText: (
      <FormattedMessage id="setting.plan.td11.value" values={{ page: 2500 }} />
    ),
    isShowLangingPage: true,
  },
  {
    planName: <FormattedMessage id="setting.plan.td" />,
    icon: <PriceMultipleDocumentsIcon />,
    free: false,
    freeText: "1",
    standard: false,
    standardText: "5",
    professional: false,
    professionalText: "5",
    team: false,
    teamText: "5",
    business: false,
    businessText: "5",
    isShowLangingPage: true,
  },
  {
    planName: <FormattedMessage id="setting.plan.td12" />,
    icon: <PriceMultipleDocumentsIcon />,
    free: false,
    freeText: <FormattedMessage id="setting.plan.td12.value.no" />,
    standard: false,
    standardText: <FormattedMessage id="setting.plan.td12.value.yes" />,
    professional: false,
    professionalText: <FormattedMessage id="setting.plan.td12.value.yes" />,
    team: false,
    teamText: <FormattedMessage id="setting.plan.td12.value.yes" />,
    business: false,
    businessText: <FormattedMessage id="setting.plan.td12.value.yes" />,
    isShowLangingPage: true,
  },
];

const IterFont = styled.span`
  font-family: ${({ theme }) => theme.regularFont};
`;

export const PricingTable = ({
  userDetail,
  requiredPlan,
  upgradePlanLoading,
  duration,
  plan,
  onUpgradePlan,
  toggleCancelPaymentModal,
}: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);

  const { formatMessage } = useIntl();
  const active = -1;

  const thClassNames = (index: number) => {
    return index === active ? "flex flex-col p-5" : "flex flex-col p-5";
  };

  const tdClassNames = (index: number, trIndex?: number) => {
    let classes = `p-5 text-center flex items-center justify-center h-16`;
    if (index === active) {
      classes = `p-5 flex items-center justify-center h-16`;

      if (trIndex && trIndex + 1 === planTable.length) {
        classes = `p-5 flex items-center justify-center h-16`;
      }
    }
    return classes;
  };

  return (
    <Fragment>
      <MobilePricing
        isLoggedIn={true}
        requiredPlan={requiredPlan}
        upgradePlanLoading={upgradePlanLoading}
        onUpgradePlan={onUpgradePlan}
        plans={plan}
        isSwitch={false}
        duration={duration}
        userDetail={userDetail}
        theme={theme}
      />
      <div className="max-w-[100%] mx-auto w-full px-[15px] md:px-0 hidden lg:block">
        <div
          className={classNames(
            "flex flex-col w-full overflow-auto mb-[30px]",
            styles.tableContainer,
            {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            }
          )}
        >
          <table
            className={classNames(styles.planTable, {
              [styles.planTableLight]: theme === "light",
              [styles.planTableDark]: theme === "dark",
            })}
            cellPadding={0}
            cellSpacing={0}
          >
            <thead>
              <tr>
                <th></th>
                {plan
                  .filter((item) => {
                    if (item.name === "Free") {
                      return item;
                    }
                    if (item.duration === "month" && duration === "month") {
                      return item;
                    }
                    if (item.duration === "year" && duration === "year") {
                      return item;
                    }
                  })
                  .map((planItem, index) => (
                    <th
                      className="text-left text-sm leading-6"
                      key={`th-${index}`}
                    >
                      <div className={thClassNames(index + 2)}>
                        <span className="font-medium mb-[10px]">
                          {planItem.name}
                        </span>
                        <span className="text-[32px] leading-[40px] font-[600] font-inter mb-[16px]">
                          ${planItem.discounted_price}
                          {planItem.name !== "Free" && (
                            <span className="text-xl">/m</span>
                          )}
                        </span>
                        <div className="flex justify-between mb-[16px]">
                          <span className="leading-[26px]">
                            <FormattedMessage id="landing.price.credits" />
                          </span>
                          <IterFont className="leading-[26px]">
                            {`${planItem.credits}/${planItem.name === "Free"
                                ? formatMessage({
                                  id: "landing.price.table.duration.day",
                                })
                                : formatMessage({
                                  id: "landing.price.table.duration.month",
                                })
                              }`}
                          </IterFont>
                        </div>
                        {planItem.name ===
                          userDetail?.user.activeSubscription.name &&
                          planItem.duration ===
                          userDetail.user.activeSubscription.duration ? (
                          <CustomButton
                            data-testid={planItem.name}
                            variant="outlined"
                            className={classNames(styles.selectedButton, {
                              [styles.light]: theme === "light",
                              [styles.dark]: theme === "dark",
                            })}
                          >
                            <FormattedMessage id="setting.plan.selected" />
                          </CustomButton>
                        ) : (
                          <CustomButton
                            data-testid={planItem.name}
                            variant="primary"
                            isloading={
                              planItem.id === requiredPlan?.id &&
                              upgradePlanLoading
                            }
                            onClick={() => {
                              if (!upgradePlanLoading)
                                onUpgradePlan?.(planItem);
                            }}
                          >
                            <FormattedMessage id="setting.plan.changePlan" />
                          </CustomButton>
                        )}
                      </div>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {planTable.map((plan, index) => (
                <tr key={`td-${index}`}>
                  <td className="text-center">
                    <span className="text-[14px] font-semibold inline-block ml-2 leading-[26px]">
                      {plan.planName}
                    </span>
                  </td>
                  <td className="text-center">
                    <div className={tdClassNames(2, index)}>
                      {plan.free ? <TickIcon /> : plan.freeText}
                    </div>
                  </td>
                  <td className="text-center">
                    <div className={tdClassNames(3, index)}>
                      {plan.standard ? <TickIcon /> : plan.standardText}
                    </div>
                  </td>
                  <td className="text-center">
                    <div className={tdClassNames(4, index)}>
                      {plan.professional ? <TickIcon /> : plan.professionalText}
                    </div>
                  </td>
                  <td className="text-center">
                    <div className={tdClassNames(5, index)}>
                      {plan.team ? <TickIcon /> : plan.teamText}
                    </div>
                  </td>
                  <td className="text-center">
                    <div className={tdClassNames(6, index)}>
                      {plan.business ? <TickIcon /> : plan.businessText}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
   
      <Separator height="16px" />
      <div className="mx-auto flex flex-col items-center leading-8 text-center">
        <p
          className={classNames("text-[16px]", styles.extraQueryText, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
          })}
        >
          <FormattedMessage
            id="landing.price.table.bottom"
            values={{
              number: (
                <span className="font-semibold">
                  <IterFont>100000</IterFont>
                </span>
              ),
              price: (
                <span className="font-semibold">
                  <IterFont>$500</IterFont>
                </span>
              ),
            }}
          />
        </p>
      </div>
      {userDetail?.user.activeSubscription.name !== "Free" &&
        userDetail?.user.activeSubscription.is_cancelled === 0 && (
          <>
            <Separator height="30px" />
            <div className="mx-auto flex flex-col items-center leading-8">
              <CustomButton
                variant="outlined-primary"
                onClick={toggleCancelPaymentModal}
                data-testid='cancel-subscription'
              >
                <div
                  className={classNames(styles.buttonText, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                  })}
                >
                  <FormattedMessage id="setting.plan.cancelSubscription" />
                </div>
              </CustomButton>
            </div>
          </>
        )}
      <Separator height="16px" />
      </div>
      </div>
    </Fragment>
  );
};
