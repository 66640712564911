import React, { useState, useRef, useEffect } from "react";
import styles from "./answerContainer.module.scss";
import { Spinner } from "components";
import { VideoPauseIcon, VideoPlayIcon } from "../icons/VideoPlayIcon";
import { useAudio } from "hooks/services/AudioContext"; // Using Audio Context for better media management

interface IFileLinkProps {
  filePath: string;
  keyValue?: number;
  setVideoPlayer: React.Dispatch<React.SetStateAction<boolean>>;
  messageId: number;
}

export const RenderVideo: React.FC<IFileLinkProps> = ({ filePath, keyValue, setVideoPlayer ,messageId}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const { stopAudio, playVideo, isPlayingVideo, setIsPlaying, setIsPlayingVideo, setCurrentMessageId
    , currentVideoMessageId,setCurrentVideoMessageId, setIsAudioLoading 
} = useAudio(); // Access methods and states from AudioContext
// const [isPlay, setIsPlay] = useState(false);

  const timeoutRef = useRef<number | null>(null);
  const [showPauseIcon, setShowPauseIcon] = useState(false);

  const handlePlayPause = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      if (videoElement.paused) {
        // Pause currently playing video if it exists
        if (isPlayingVideo) {
          videoElement.pause();
          setIsPlayingVideo(false); // Stop video playback
        }
        // Stop audio playback
        stopAudio();
        setCurrentMessageId(null);
        setIsAudioLoading(true);

        playVideo(videoElement,messageId); // Play the video
        setShowPauseIcon(true); // Show pause icon immediately
        hidePauseIconAfterDelay(); // Hide after a delay
      } else {
        videoElement.pause();
        setCurrentVideoMessageId(null);
        setIsPlayingVideo(false); // Stop video playback
        setShowPauseIcon(false);
      }
    }
  };

  const handleMouseMove = () => {
    if (isPlayingVideo && currentVideoMessageId === messageId ) {
      setShowPauseIcon(true); // Show pause icon on interaction
      hidePauseIconAfterDelay(); // Reset the timer to hide it
    }
  };

  const hidePauseIconAfterDelay = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => {
      setShowPauseIcon(false); // Hide pause icon after 1 second
    }, 1000);
  };

  const handleLoadedData = () => {
    setIsLoaded(true); // Video is fully loaded
  };

  const handleVideoError = () => {
    setVideoPlayer(false);
    setIsLoaded(false);
  };

  // Clear timeout on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setCurrentVideoMessageId(null);
    };
  }, []);

  return (
    <div
      className={styles.videoContainer}
      style={{
        position: "relative",
        width: "100%",
        maxWidth: "100%",
        maxHeight: "263px",
      }}
      onMouseMove={handleMouseMove}
    >
      {!isLoaded && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spinner extraSmall delFile />
        </div>
      )}

      <video
        key={keyValue}
        ref={videoRef}
        controls={false}
        className="rounded-lg"
        style={{
          objectFit: "contain",
          maxWidth: "100%",
          maxHeight: "263px",
          width: "100%",
        }}
        onPlay={() => {setIsPlaying(true)
        }}
        onPause={() => {setIsPlaying(false)
        }}
        onLoadedData={handleLoadedData}
        onError={handleVideoError}
        onClick={() => {
          if (isPlayingVideo && currentVideoMessageId === messageId) {
            setShowPauseIcon(true);
          }
        }}
      >
        <source src={filePath} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {(isLoaded 
      // && !isPlayingVideo
       && ((currentVideoMessageId !== messageId || currentVideoMessageId === null))) && (
        <div
          className={styles.playIcon}
          onClick={handlePlayPause}
        >
          <VideoPlayIcon />
        </div>
      )}

      {isPlayingVideo &&
    //   && isPlay
    currentVideoMessageId === messageId
       && showPauseIcon && (
        <div
          className={styles.playIcon}
          onClick={handlePlayPause}
          style={{ opacity: showPauseIcon ? 1 : 0 }}
        >
          <VideoPauseIcon />
        </div>
      )}
    </div>
  );
};
