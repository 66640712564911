import { useCallback } from "react";
import { useEffectOnce } from "react-use";

import { AuthContainer } from "../components/AuthContainer";
import { LoadingPage } from "components";

import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";

import {socialCallback } from "redux/actions";
import { RoutePaths } from "pages/routePaths";

export const SocialAuthVerificationPage = () => {
  const { query, push } = useRouter();
  const { triggerNotification } = useAppNotification();

  const onVerifyEmail = useCallback(() => {
    if (Object.keys(query).length > 1) {
      const { authuser, code, prompt, scope} = query;
        socialCallback({ authuser, code, prompt, scope })
          .then(() => {
            push(`/${RoutePaths.Chat}`);
          })
          .catch((err: any) => {
            triggerNotification({ message: err?.data?.message, type: "error" });
          });
      }
  }, [query, push, triggerNotification]);

  useEffectOnce(() => onVerifyEmail());

  return (
    <AuthContainer>
      <LoadingPage />
    </AuthContainer>
  );
};
