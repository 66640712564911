import { Dispatch, SetStateAction } from "react";
import { validateFileSize, validateFileType } from "./functions";
import { IErrorMessage, IFileErrorState } from "pages/ChatPage/pages"; 
import { IUserDetail } from "redux/actions";


const AllowedFileTypes = [
    "pdf",
    "txt",
    "ppt",
    "pptx",
    // "doc",
    "docx",
    "csv",
    "xls",
    "xlsx",
    "eml",
    "srt",
    "png",
    "jpg",
    "jpeg",
    "webp",
    "gif",
    "mp4",
    "mpeg",
    "mpg",
    "webm",
    "wmv",
    "3gpp",
];
const AllowedFileSize = 100;
const FreePlanAllowedFileSize = 10;
const StandardPlanAllowedFileSize = 50;


export const validateImgFile = (
  files: File[],
  setErrorMessage: Dispatch<SetStateAction<IErrorMessage[]>> | undefined,
  claudAllowedFileSize: number,
  formatMessage: any
): boolean => {
  let isValid = true;
  let allowedFileSize = claudAllowedFileSize;

  for (let file of files) {
    if (!validateFileType({ file: file, types: AllowedFileTypes })) {
      setErrorMessage?.([]);
      const formattedErrorMessage = formatMessage(
        { id: "documentChat.file.fileFormatImage" },
        { name: file.name }
      );
      setErrorMessage &&
        setErrorMessage((prevErrors) => [
          ...prevErrors,
          { name: file.name, error: formattedErrorMessage },
        ]);
      isValid = false;
      break;
    }

    if (
      !validateFileSize({
        file,
        sizeOptions: { size: allowedFileSize, unit: "MB" },
      })
    ) {
      setErrorMessage?.([]);
      const formattedErrorMessage = formatMessage(
        { id: "documentChat.file.fileSizeImage" },
        { name: file.name, size: allowedFileSize }
      );
      setErrorMessage &&
        setErrorMessage((prevErrors) => [
          ...prevErrors,
          { name: file.name, error: formattedErrorMessage },
        ]);
      isValid = false;
      break;
    }
  }

  return isValid;
};


export const validateFile = (
  files: File[],
  setIsMainScreenOpen: Dispatch<SetStateAction<boolean>> | undefined,
  setErrorModal: Dispatch<SetStateAction<IFileErrorState>>,
  userDetail: IUserDetail | undefined,
  setMessageId?: Dispatch<SetStateAction<string>>,
  // fileType?: "document" | "video",
): boolean => {
  let isValid = true;
  const planName = userDetail?.user.activeSubscription.name.toLowerCase();

  if (!files || files.length === 0) {
    setErrorModal({ message: "documentChat.file.required", show: true });
    setIsMainScreenOpen?.(true);
    return false;
  }

    for (let file of files) {
      if (!validateFileType({ file, types: AllowedFileTypes })) {
        setErrorModal({ message: "documentChat.file.fileFormat", show: true });
        setIsMainScreenOpen?.(true);
        isValid = false;
        break;
      }

      // if(fileType !== "video"){
      let allowedFileSize = AllowedFileSize;

      if (planName === "free") {
        allowedFileSize = FreePlanAllowedFileSize;
      } else if (planName === "standard") {
        allowedFileSize = StandardPlanAllowedFileSize;
      }

      const valid = validateFileSize({
        file,
        sizeOptions: { size: allowedFileSize, unit: "MB" },
      });

      if (!valid) {
        if (planName === "free" || planName === "standard") {
          setMessageId &&
            setMessageId(`documentChat.file.${planName}.plan.fileSize`);
        } else {
          setErrorModal({ message: "documentChat.file.fileSize", show: true });
          setIsMainScreenOpen?.(true);
        }
        isValid = false;
        break;
      }
    // }
    }
  return isValid;
};