export const GoogleSheetIcon = () => (
    <svg
      width="20"
      height="22"
      viewBox="0 0 24 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path
          id="Vector"
          d="M15.75 0L24 8.25L19.875 9L15.75 8.25L15 4.125L15.75 0Z"
          fill="#188038"
        />
        <path
          id="Vector_2"
          d="M15.75 8.25V0H2.25C1.00688 0 0 1.00688 0 2.25V30.75C0 31.9931 1.00688 33 2.25 33H21.75C22.9931 33 24 31.9931 24 30.75V8.25H15.75Z"
          fill="#34A853"
        />
        <path
          id="Vector_3"
          d="M4.5 12.75V23.625H19.5V12.75H4.5ZM11.0625 21.75H6.375V19.125H11.0625V21.75ZM11.0625 17.25H6.375V14.625H11.0625V17.25ZM17.625 21.75H12.9375V19.125H17.625V21.75ZM17.625 17.25H12.9375V14.625H17.625V17.25Z"
          fill="white"
        />
      </g>
    </svg>
  );