import { Main } from "components/Main";
import { useIntl } from "react-intl";
import { useSelector } from "redux/hooks";
import { useEffectOnce } from "react-use";

import {
  GlobalReachIcon,
  InstantTranslationIcon,
  UnderstandingCulturesIcon,
  SaveTimeIcon,
  EasyToUseIcon,
  AccessibleIcon,
  PickLanguagesIcon,
  StartChattingIcon,
  SeeTranslationIcon,
} from "pages/Features/MultiLanguageCommunication/icons";

import {
  Hero,
  IImage,
  PerksOfFeature,
  IFeatureItem,
  HowToUseTheFeature,
  IFeature,
  Faqs,
  IFaq,
  iconSizeClassName,
  iconClassName,
} from "pages/Features/components";

const heroImages: IImage[] = [
  {
    src: "/feature/multiLanguage/image1.png",
    alt: "",
  },
  {
    src: "/feature/multiLanguage/image2.png",
    alt: "",
  },
  {
    src: "/feature/multiLanguage/image3.png",
    alt: "",
  },
  {
    src: "/feature/multiLanguage/image4.png",
    alt: "",
  },
  {
    src: "/feature/multiLanguage/image5.png",
    alt: "",
  },
  {
    src: "/feature/multiLanguage/image6.png",
    alt: "",
  },
];


const featureItems: IFeatureItem[] = [
  {
    icon: <GlobalReachIcon className={iconSizeClassName} />,
    title: "feature.multiLanguage.globalReachTitle",
    content: "feature.multiLanguage.globalReachContent",
  },
  {
    icon: <InstantTranslationIcon className={iconSizeClassName} />,
    title: "feature.multiLanguage.instantTranslationTitle",
    content: "feature.multiLanguage.instantTranslationContent",
  },
  {
    icon: <UnderstandingCulturesIcon className={iconSizeClassName} />,
    title: "feature.multiLanguage.understandingCulturesTitle",
    content: "feature.multiLanguage.understandingCulturesContent",
  },
  {
    icon: <SaveTimeIcon className={iconSizeClassName} />,
    title: "feature.multiLanguage.saveTimeTitle",
    content: "feature.multiLanguage.saveTimeContent",
  },
  {
    icon: <EasyToUseIcon className={iconSizeClassName} />,
    title: "feature.multiLanguage.easyToUseTitle",
    content: "feature.multiLanguage.easyToUseContent",
  },
  {
    icon: <AccessibleIcon className={iconSizeClassName} />,
    title: "feature.multiLanguage.accessibleTitle",
    content: "feature.multiLanguage.accessibleContent",
  },
];

const features: IFeature[] = [
  {
    icon: <PickLanguagesIcon className={iconClassName} />,
    title: "feature.multiLanguage.pickLanguagesTitle",
    content: "feature.multiLanguage.pickLanguagesContent",
  },
  {
    icon: <StartChattingIcon className={iconClassName} />,
    title: "feature.multiLanguage.startChattingTitle",
    content: "feature.multiLanguage.startChattingContent",
  },
  {
    icon: <SeeTranslationIcon className={iconClassName} />,
    title: "feature.multiLanguage.seeTranslationTitle",
    content: "feature.multiLanguage.seeTranslationContent",
  },
];

const faqs: IFaq[] = [
  {
    title: "feature.multiLanguage.faq1Title",
    content: "feature.multiLanguage.faq1Content",
  },
  {
    title: "feature.multiLanguage.faq2Title",
    content: "feature.multiLanguage.faq2Content",
  },
  {
    title: "feature.multiLanguage.faq3Title",
    content: "feature.multiLanguage.faq3Content",
  },
];

export const MultiLanguageCommunicationPage = () => {
  const { formatMessage } = useIntl();
    const themeMode = useSelector((state) => state.authReducer.theme);

    useEffectOnce(() => {
      window.scrollTo(0, 0);
    });

  return (
    <Main 
    // isHasBgOnFooter
     activeSection="multiLanguageCommunication">
      <Hero
        themeMode={themeMode}
        images={heroImages}
        title={formatMessage({ id: "feature.multiLanguage.title" })}
        content={formatMessage({ id: "feature.multiLanguage.content" })}
      />
      <PerksOfFeature features={featureItems} themeMode={themeMode} />
      <HowToUseTheFeature features={features} themeMode={themeMode} />
      <Faqs faqs={faqs} themeMode={themeMode} />
    </Main>
  );
};


