import { useCallback } from "react";
import { useEffectOnce } from "react-use";

import { LoadingPage } from "components";

import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";

import { createGoogleSheet, googleCallback } from "redux/actions";

export const SocialGoogleSheetAuthPage = () => {
  const { query, push } = useRouter();
  const { triggerNotification } = useAppNotification();

  const onVerifyEmail = useCallback(() => {
    if (Object.keys(query).length > 1) {
      const { authuser, code, prompt, scope, state, redirect_uri, client_id, response_type} = query;
        
        googleCallback({ state, code, scope, authuser, prompt,redirect_uri,client_id,response_type})
          .then((res) => {
            const googleToken = res?.data?.google_token;
            
            if (googleToken) {
              createGoogleSheet(googleToken)
            }
          })
          .catch((err: any) => {
            triggerNotification({ message: err?.data?.message, type: "error" });
          });
    }
  }, [query, push, triggerNotification]);

  useEffectOnce(() => onVerifyEmail());

  return (
      <LoadingPage />
  );
};