import { useS3FileUpload } from "hooks/services/AmazonServices";
import { useAppNotification } from "hooks/services/AppNotification";
import { generatePresignedURL } from "redux/actions";
import { Dispatch, SetStateAction } from "react";
import { IUploadFile } from "pages/ChatPage";

interface IProp {
    fileArray: File[],
    setUploadingFiles: Dispatch<SetStateAction<IUploadFile[]>> | undefined;
    fileType: "image" | "document" | "video";
    setIsFileUploading: Dispatch<SetStateAction<boolean>> | undefined;
    setFileS3Link?: Dispatch<SetStateAction<string[]>>;
}

export const useFileUploader = () => {
    const { uploadFile } = useS3FileUpload();
    const { triggerNotification } = useAppNotification();

    
 const generateUniqueId = () => {
    return `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
};

    const uploadToS3 = async ({
        fileArray,
        setUploadingFiles,
        fileType,
        setIsFileUploading,
        setFileS3Link,
    }: IProp) => {
        fileArray.forEach((file) => {
            setUploadingFiles!((prev) => [
                {id: generateUniqueId(), file, status: "validating", fileType: fileType },
                ...prev,
            ]);
        });

        const preSignedURLPromises = fileArray.map((file) => {
            setUploadingFiles!((prev) =>
                prev.map((f) =>
                    f.file === file ? { ...f, status: "uploading" } : f
                )
            );
            return generatePresignedURL({
                name: `website-${new Date().getTime()}-${file.name}`,
            })
                .then((preSignedRes: any) => ({
                    file,
                    preSignedURL: new URL(preSignedRes.data.url),
                }))
                .catch((err) => {
                    triggerNotification({
                        message: err?.data?.message,
                        type: "error",
                    });
                    setUploadingFiles!((prev) =>
                        prev.map((f) =>
                            f.file === file ? { ...f, status: "error" } : f
                        )
                    );
                    setIsFileUploading!(false);
                    return null;
                });
        });
        const preSignedURLs = await Promise.all(preSignedURLPromises);
        if (preSignedURLs.some((item) => item === null)) {
            return;
        }
        const validPreSignedURLs = preSignedURLs as {
            file: File;
            preSignedURL: URL;
        }[];
        const uploadPromises = validPreSignedURLs.map(
            ({ file, preSignedURL }) => {
                setUploadingFiles!((prev) =>
                    prev.map((f) =>
                        f.file === file ? { ...f, status: "uploading" } : f
                    )
                );
                return uploadFile({
                    file: file,
                    preSignedUrl: preSignedURL.href,
                })
                    .then(() => {
                        setUploadingFiles!((prev) =>
                            prev.map((f) =>
                                f.file === file
                                    ? {
                                        ...f,
                                        status: "uploaded",
                                        S3Link: `${preSignedURL?.origin}${preSignedURL?.pathname}`,
                                    }
                                    : f
                            )
                        );
                        return { preSignedURL };
                    })
                    .catch((err) => {
                        triggerNotification({
                            message: err?.data?.message,
                            type: "error",
                        });
                        setUploadingFiles!((prev) =>
                            prev.map((f) =>
                                f.file === file ? { ...f, status: "error" } : f
                            )
                        );
                        setIsFileUploading!(false);
                        return null;
                    });
            }
        );

        const uploadedFiles = await Promise.all(uploadPromises);
        if (uploadedFiles.some((item) => item === null)) {
            return;
        }

        const imagePath = uploadedFiles.map((img) => {
            return `${img?.preSignedURL?.origin}${img?.preSignedURL?.pathname}`;
        });
        setIsFileUploading!(false);
        setFileS3Link!((prevLinks) => [...imagePath, ...prevLinks]);
    };

    return { uploadToS3 };
};