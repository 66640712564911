import { Fragment, useEffect, useState } from "react";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { RoutePaths } from "./routePaths";
import useRouter from "hooks/useRouter";

import { LandingPage } from "./LandingPage";
import {
  LoginPage,
  SignupPage,
  EmailSent,
  VerifyEmailPage,
  SocialAuthVerificationPage,
  AcceptInvitationPage,
  ConfirmEmailRegistration,
} from "./AuthPages";
import { ChatPage } from "./ChatPage";
import { SettingsPage } from "./SettingsPage";
import { GuidePage } from "./GuidePage";
import Terms from "./Terms";
import Privacy from "./Privacy";
import { updateLanguage } from "redux/actions";

import { Notification } from "components";
import {
  useAppNotification,
  appNotificationInitialState,
} from "hooks/services/AppNotification";
import { useSelector } from "redux/hooks";
import { ReferralPage } from "./AuthPages/ReferralPage";
import { fetchUserDetails, setTheme } from "redux/actions";
import { AffiliatePage } from "./AffiliatePage";
// import { System_Update } from "./LandingPage/sections/system_update";

import {
  AccessToDiverseModelsPage,
  ImageCreationPage,
  DocumentBasedChatPage,
  IntuitiveInterfacePage,
  MultiLanguageCommunicationPage,
  ChromeExtensionPage,
} from "pages/Features";
import { useEffectOnce } from "react-use";
import { EmailChangeVerification } from "./AuthPages/EmailChangeVerification";
import { SocialGoogleSheetAuthPage } from "./AuthPages/SocialGoogleSheetAuthPage";

const AuthenticatedRoutes = () => {
  const { triggerNotification } = useAppNotification();

  useEffect(() => {
    fetchUserDetails().catch((err) => {
      triggerNotification({ message: err?.data?.message, type: "error" });
    });
  }, [triggerNotification]);
  
  return (
    <Routes>
      <Route path={`/${RoutePaths.Affiliate}`} element={<AffiliatePage />} />
      <Route path={`/${RoutePaths.Chat}/*`} element={<ChatPage />} />
      <Route path={`/${RoutePaths.Settings}/*`} element={<SettingsPage />} />
      <Route path={`/${RoutePaths.ShareChat}/*`} element={<ChatPage />} />
      <Route
        path={`/${RoutePaths.AcceptInvitation}`}
        element={<AcceptInvitationPage isAuthenticated={true} />}
      />
      <Route
        path={`/${RoutePaths.AcceptInvitation}`}
        element={<AcceptInvitationPage isAuthenticated={true} />}
      />
      <Route
        path={`/${RoutePaths.VerifyChangeEmail}/:token`}
        element={<EmailChangeVerification />}
      />
  
        <Route
        path={`/${RoutePaths.VerifyGoogleSheetAuth}`}
        element={<SocialGoogleSheetAuthPage />}
      />

      <Route path="*" element={<AutoMoveToChatPage />} />

    </Routes>
  );
};

export const AutoMoveToChatPage = () => {
  const isShare = window.location.pathname.includes("share-chat");
  const token = window.location.pathname.split("/").pop();

  if (isShare) return <Navigate to={`/share-chat/${token}`} replace />;
  else return <Navigate to={`/${RoutePaths.Chat}`} replace />;
};

const UnAuthenticatedRoutes = ({ lang }: { lang: string | undefined }) => {

  const { pathname } = useRouter();
  const [language, setLanguage] = useState<string>('');

  useEffectOnce(() => {
    const validLanguages = ['en', 'cs', 'da', 'de', 'es', 'fi', 'fr', 'id', 'it', 'ja', 'ko', 'ms', 'nl', 'no', 'pl', 'pt', 'ru', 'sv', 'tr', 'zh', 'zh-TW'];
    let language = pathname.split("/")[1];
    if (validLanguages.includes(language)) {
      setLanguage(language)
      updateLanguage(language)
      setTimeout(() => setLanguage(''), 200);
    }
  })

  const routes = [
    { path: RoutePaths.Root, element: <LandingPage /> },
    { path: RoutePaths.Signup, element: <SignupPage /> },
    { path: RoutePaths.Login, element: <LoginPage /> },
    { path: RoutePaths.EmailSent, element: <EmailSent /> },
    { path: RoutePaths.Affiliate, element: <AffiliatePage /> },
    // { path: RoutePaths.System_Update, element: <System_Update/> },
    { path: RoutePaths.Guide, element: <GuidePage /> },
    { path: RoutePaths.Terms, element: <Terms /> },
    { path: RoutePaths.Privacy, element: <Privacy /> },
    {
      path: `${RoutePaths.Feature}/${RoutePaths.AccessToDiverseModels}`,
      element: <AccessToDiverseModelsPage />,
    },
    {
      path: `${RoutePaths.Feature}/${RoutePaths.ImageCreation}`,
      element: <ImageCreationPage />,
    },
    {
      path: `${RoutePaths.Feature}/${RoutePaths.DocumentBasedChat}`,
      element: <DocumentBasedChatPage />,
    },
    {
      path: `${RoutePaths.Feature}/${RoutePaths.IntuitiveInterface}`,
      element: <IntuitiveInterfacePage />,
    },
    {
      path: `${RoutePaths.Feature}/${RoutePaths.MultiLanguageCommunication}`,
      element: <MultiLanguageCommunicationPage />,
    },
    {
      path: `${RoutePaths.Feature}/${RoutePaths.ChromeExtension}`,
      element: <ChromeExtensionPage />,
    },
  ];

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={language !== '' ? `/${language}/${route.path}` : `/${lang}/${route.path}`}
          element={route.element}
        />
      ))}

      <Route
        path={`/${RoutePaths.ConfirmEmailRegistration}`}
        element={<ConfirmEmailRegistration />}
      />
      <Route
        path={`/${RoutePaths.VerifyEmail}/:token`}
        element={<VerifyEmailPage />}
      />

      <Route
        path={`/${RoutePaths.VerifyChangeEmail}/:token`}
        element={<EmailChangeVerification />}
      />
      <Route
        path={`/${RoutePaths.VerifySocialAuth}`}
        element={<SocialAuthVerificationPage />}
      />
      <Route
        path={`/${RoutePaths.AcceptInvitation}`}
        element={<AcceptInvitationPage isAuthenticated={false} />}
      />
      <Route path={`/${RoutePaths.Referral}`} element={<ReferralPage />} />
      <Route path={`/${RoutePaths.ShareChat}/*`} element={<ChatPage />} />
      <Route path="*" element={<AutoMoveToSignin lang={lang} />} />
    </Routes>
  );
};


export const AutoMoveToSignin = ({ lang }: { lang: string | undefined }) => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    const newPath = replaceLangInPath(currentPath, lang);
    const parts = currentPath.split("/");
    if (parts.length >= 2) {
      if (parts[1] === lang)
        navigate(`/${lang}/${RoutePaths.Login}`, { replace: true });
      else navigate(newPath, { replace: true });
    } else navigate(`/${lang}/${RoutePaths.Login}`, { replace: true });
  }, [lang, location.pathname, navigate]);

  return null;
};

const replaceLangInPath = (
  path: string,
  newLang: string | undefined
): string => {
  const parts = path.split("/");
  if (parts.length >= 2) {
    parts[1] = newLang || "";
  }
  return parts.join("/");
};

export const Routing = () => {
  const { notification, triggerNotification } = useAppNotification();
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const theme = useSelector((state) => state.authReducer.theme);

  useEffect(() => {
    if (!theme) setTheme("dark");
  }, [theme]);
  return (
    <Fragment>
      <Notification
        message={notification.message}
        type={notification.type}
        onClose={() => triggerNotification(appNotificationInitialState)}
      />
      {userDetail?.token && <AuthenticatedRoutes />}
      {!userDetail?.token && (
        <UnAuthenticatedRoutes
          lang={userDetail?.user?.language ? userDetail?.user?.language : "en"}
        />
      )}
    </Fragment>
  );
};
