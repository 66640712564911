import { memo, useState, useEffect, useRef, ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import styles from "./renderTable.module.scss";

import { useSelector } from "redux/hooks";
import { GoogleSheetIcon } from "../icons/GoogleSheetIcon";
import { TableCopyIcon } from "../icons/TableCopyIcon";
import { Spinner } from "components";
import { SharedIcon } from "../icons/SharedIcon";
// import { isChrome } from "utils/functions";
import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";

import { createGoogleSheet, sendTableDataToBackend } from "redux/actions/auth";
import { useIntl } from "react-intl";
interface Iprops {
  children: ReactNode;
}

export const RenderTable = memo(({ children }: Iprops) => {

  const { theme } = useSelector((state) => state.authReducer);
  const { pathname } = useRouter();
  const history = pathname.includes("/chat/history");

  const { messages, newMessages } = useSelector((state) => state.chatReducer);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const tableRef = useRef<HTMLTableElement>(null);
  const [isloading, setIsloading] = useState<boolean>(false);

  const { triggerNotification } = useAppNotification();
  const { formatMessage } = useIntl();
  const isShareChat = pathname.includes("share-chat");

  useEffect(() => {
    const intervalID = setTimeout(() => {
      if (isCopied) setIsCopied(false);
    }, 1000);

    return () => clearInterval(intervalID);
  }, [isCopied]);

  const getTableData = (isCopy = true) => {
    const table = tableRef.current;

    if (!table) {
      console.error("Table reference is null");
      return;
    }

    const rows = table.getElementsByTagName("tr");

    const tableData: string[][] = [];

    const headerRow = Array.from(rows[0].getElementsByTagName("th")).map(
      (cell) => cell.innerText
    );
    tableData.push(headerRow);

    for (let i = 1; i < rows.length; i++) {
      const cells = rows[i].getElementsByTagName("td");
      const rowData = Array.from(cells).map((cell) => cell.innerText);
      tableData.push(rowData);
    }

    const textData = tableData.map((row) => row.join("\t")).join("\n");

    if (!isCopy) {
      return textData;
    }
    navigator.clipboard
      .writeText(textData)
      .then(() => {
        setIsCopied(true);
      })
      .catch((err) => {
        console.error("Copy error", err);
      });
  };

  const handleTableData = async (chatId: number) => {

    try {
      setIsloading(true);
      const response: string = await sendTableDataToBackend(chatId);
      if (response) {
        window.open(response, "_blank");
      } else {
        console.error("No URL returned from backend.");
      }
      setIsloading(false);
      localStorage.removeItem("spreadsheetTable_chatId");
    } catch (error) {
      setIsloading(false);
      console.error("Error sending table data to backend:", error);
      localStorage.removeItem("spreadsheetTable_chatId");
    }
  }

  const onOpenGoogleSheets = () => {

    let chatId: number;
    if (history) {
      chatId = messages[0].chat_id ?? 0;
    } else {
      chatId = newMessages[0].chat_id ?? 0;
    }

    const tableData = getTableData(false);
    localStorage.setItem("tableData", JSON.stringify(tableData));
    const storedUserDetail = JSON.parse(localStorage.getItem("DGPT_USER") ?? '{}');

    // const isAuthenticate = storedUserDetail?.userDetail?.user?.social_providers?.[0]?.token || false;

    // Access the token from the userDetail
    const isAuthenticate = storedUserDetail?.userDetail?.user?.social_providers?.[storedUserDetail?.userDetail?.user?.social_providers.length - 1]?.token || false;
    localStorage.setItem("spreadsheetTable_chatId", JSON.stringify(chatId));

    if (isAuthenticate) {
      const authuser = true;
      setIsloading(true);
      createGoogleSheet(isAuthenticate, authuser)
        .then(() => {
          setIsloading(false);
        })
        .catch((err: any) => {
          if (err?.result === false) {
            triggerNotification({ message: formatMessage({ id: "googleSheetsNotification" }), type: "error" });
            const storedChatId = JSON.parse(localStorage.getItem("spreadsheetTable_chatId") || '0');
            handleTableData(storedChatId);
          }
        })
    }
    else handleTableData(chatId);
  };

  return (
    <div
      className={classNames(styles.tableContainer, {
        [styles.tableContainerLight]: theme === "light",
        [styles.tableContainerDark]: theme === "dark",
      })}
    >
      <table ref={tableRef}>{children}</table>
      {!isShareChat ? (
        <div className="flex items-center my-4">

          <button
            className={`${styles.googleContainer} mr-[10px] flex items-center justify-center bg-transparent rounded-[10px] py-2 px-4`}
            onClick={onOpenGoogleSheets}
          >
            <div
              className={`flex items-center ${isloading ? 'opacity-0 pointer-events-none' : 'opacity-100'
                } transition-opacity duration-200`}
            >
              <GoogleSheetIcon />
              <span className="inline-flex text-sm whitespace-nowrap mx-[6px] dark:text-[#fff]">
                <FormattedMessage id="googleSheets" />
              </span>
              <span className="pl-[20px]"><SharedIcon theme={theme} /></span> 
            </div>
            {isloading && (
              <div className="absolute flex items-center justify-center">
                <Spinner extraSmall />
              </div>

            )}
    
          </button>
          <div className="pt-[6px]">
            {isCopied ? (
              <FormattedMessage id="copied" />
            ) : (
              <div className="cursor-pointer" onClick={(event: React.MouseEvent) => {
                event.stopPropagation();
                getTableData(true);
              }}>
                <TableCopyIcon />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.rows}>
          {!isCopied ? (
            <div data-testid='get-table-data' className={styles.copyTableBtn} onClick={() => { getTableData(true) }}>
              <span className={styles.cursor}>
                <FormattedMessage id="answer.tooltip.copyTable" />
              </span>
            </div>
          ) : (
            <div data-testid='get-table-data' className={styles.copyTableBtn} onClick={() => { getTableData(false) }}>
              <span>
                <FormattedMessage id="answer.tooltip.copied" />
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
});
