import { Main } from "components/Main";
import { useIntl } from "react-intl";
import { useSelector } from "redux/hooks";
import { useEffectOnce } from "react-use";

import {
  CreativityEnhancementIcon,
  TimeEfficiencyIcon,
  CustomizationIcon,
  DiversityIcon,
  ExplorationIcon,
  CostEffectivenessIcon,
} from "pages/Features/ImageCreation/icons";

import {
  Hero,
  IImage,
  PerksOfFeature,
  IFeatureItem,
  iconSizeClassName,
  Faqs,
  IFaq,
} from "pages/Features/components";

import { HowToUse } from "./HowToUse";

const heroImages: IImage[] = [
  {
    src: "/feature/imageGenerator/image1.png",
    alt: "",
  },
  {
    src: "/feature/imageGenerator/image2.png",
    alt: "",
  },
  {
    src: "/feature/imageGenerator/image3.png",
    alt: "",
  },
  {
    src: "/feature/imageGenerator/image4.png",
    alt: "",
  },
  {
    src: "/feature/imageGenerator/image5.png",
    alt: "",
  },
  {
    src: "/feature/imageGenerator/image6.png",
    alt: "",
  },
];

const featureItems: IFeatureItem[] = [
  {
    icon: <CreativityEnhancementIcon className={iconSizeClassName} />,
    title: "feature.imageGenerator.creativityEnhancementTitle",
    content: "feature.imageGenerator.creativityEnhancementContent",
  },
  {
    icon: <TimeEfficiencyIcon className={iconSizeClassName} />,
    title: "feature.imageGenerator.timeEfficiencyTitle",
    content: "feature.imageGenerator.timeEfficiencyContent",
  },
  {
    icon: <CustomizationIcon className={iconSizeClassName} />,
    title: "feature.imageGenerator.customizationTitle",
    content: "feature.imageGenerator.customizationContent",
  },
  {
    icon: <DiversityIcon className={iconSizeClassName} />,
    title: "feature.imageGenerator.diversityTitle",
    content: "feature.imageGenerator.diversityContent",
  },
  {
    icon: <ExplorationIcon className={iconSizeClassName} />,
    title: "feature.imageGenerator.explorationTitle",
    content: "feature.imageGenerator.explorationContent",
  },
  {
    icon: <CostEffectivenessIcon className={iconSizeClassName} />,
    title: "feature.imageGenerator.costEffectivenessTitle",
    content: "feature.imageGenerator.costEffectivenessContent",
  },
];

const faqs: IFaq[] = [
  {
    title: "feature.imageGenerator.faq1Title",
    content: "feature.imageGenerator.faq1Content",
  },
  {
    title: "feature.imageGenerator.faq2Title",
    content: "feature.imageGenerator.faq2Content",
  },
  {
    title: "feature.imageGenerator.faq3Title",
    content: "feature.imageGenerator.faq3Content",
  },
];

export const ImageCreationPage = () => {
  const { formatMessage } = useIntl();
  const themeMode = useSelector((state) => state.authReducer.theme);

  useEffectOnce(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Main isHasBgOnFooter activeSection="imageCreation">
      <Hero
        themeMode={themeMode}
        images={heroImages}
        title={formatMessage({ id: "feature.imageGenerator.title" })}
        content={formatMessage({ id: "feature.imageGenerator.content" })}
      />
      <PerksOfFeature
        features={featureItems}
        themeMode={themeMode}
        title="feature.perksOfAllImageGenerator.title"
      />
      <HowToUse themeMode={themeMode} />
      <Faqs faqs={faqs} themeMode={themeMode} />
    </Main>
  );
};
