import { Fragment, memo, Dispatch, SetStateAction, useEffect } from "react";
import styles from "./Sidebar.module.scss";
import settingStyles from "./component/SettingSidebar/SettingSidebar.module.scss";
import classNames from "classnames";
import { BurgerIcon } from "../Navbar/components/icons";

import useRouter from "hooks/useRouter";
import { ChatSidebar, SettingSidebar } from "./component";
import { Credits } from "../Navbar/components/ChatNav/components/Credits";
import { RoutePaths } from "pages/routePaths";

import { useSelector } from "redux/hooks";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";
import { IChat } from "redux/actions";
import { ChatMiniSidebar } from "./component/ChatSidebar/components/ChatMiniSideBar";
import { SettingMiniSidebar } from "./component/SettingSidebar/components/SettingMiniSidebar";
import { useWindowSize } from "hooks/useWindowSize";

interface IProps {
  onSelectChatItem?: (chatItem: IChat | undefined) => void;
  onStartNewChat?: ({ toChat, PrivateChat }: { toChat: boolean, PrivateChat?: boolean }) => void;
  isMainScreenOpen?: boolean;
  searchQuery?: string; setSearchQuery?: Dispatch<SetStateAction<string>>;
  setOpenHistory?: Dispatch<SetStateAction<boolean>>;
  sendMessageLoading?: boolean;
  isAnswerComplete?: boolean;
  sidebarWidth: number;
  sidebarRef?: React.RefObject<HTMLDivElement>

}

export const Sidebar = memo(
  ({
    sidebarWidth,
    onSelectChatItem, onStartNewChat, isMainScreenOpen,
    searchQuery, setSearchQuery, setOpenHistory, sendMessageLoading,
    isAnswerComplete,
    // sidebarWidth,
    sidebarRef
  }: IProps) => {

    const { includeRoute } = useRouter();
    const { width } = useWindowSize();
    const { isOpen, toggleSidebar } = useToggleSidebar();
    const { theme } = useSelector((state) => state.authReducer);

    const itemStyles = (route: string) => {
      return classNames(settingStyles.menuItem, {
        [settingStyles.active]: includeRoute(route),
        [settingStyles.light]: theme === "light",
        [settingStyles.dark]: theme === "dark",
      });
    };
    const isSettings = includeRoute(RoutePaths.Settings);

    useEffect(() => {
      let lastScrollTop = 0;

      const preventPullToRefresh = (e: TouchEvent) => {
        const currentScrollTop = e.touches[0].clientY;

        if (currentScrollTop > lastScrollTop && width <= 576) {
          e.preventDefault();
        }
        lastScrollTop = currentScrollTop;
      };

      if (width <= 576 && isOpen) {
        document.addEventListener('touchmove', preventPullToRefresh, { passive: false });
      }
      else {
        document.removeEventListener('touchmove', preventPullToRefresh);
      }

      return () => {
        document.removeEventListener('touchmove', preventPullToRefresh);
      };
    }, [isOpen, width]);

    return (
      <Fragment>
        {isOpen && <div className={styles.backdrop} onClick={toggleSidebar} data-testid='backdrop-toggle-sidebar' />}

        <div ref={sidebarRef} className={classNames(styles.mainContainer, {
          [styles.isOpen]: isOpen,
          [styles.light]: theme === 'light',
          [styles.dark]: theme === 'dark',
          [styles.smallmainContainer]: !isOpen,
        })}
          style={{ width: isOpen && width > 768 ? `${sidebarWidth}px` : '' }}
        >

          {/* Left Pane */}
          <aside
            className={classNames(styles.container, {
              [styles.isOpen]: isOpen,
              [styles.lights]: theme === "light",
              [styles.darks]: theme === "dark",
              [styles.smallmainContainer]: !isOpen,
            })}
            style={{ width: isOpen && width > 768 ? `${sidebarWidth - 30}px` : '' }}
          >
            <div className={styles.header}>
              <div className={classNames(styles.logoHeader, {
                [styles.isOpen]: !isOpen,
                [styles.chatspace]: includeRoute(RoutePaths.Chat) && isOpen,
              })}>
                <button
                  className={classNames(styles.button, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                    [styles.setting]: isSettings && !isOpen,
                  })}
                  onClick={toggleSidebar}
                  data-testid='toggle-sidebar-burger-btn'
                >
                  {isSettings && !isOpen ?
                    <img
                      src={require('./logo.png')}
                      style={{ backgroundColor: "transparent", height: '44px', width: '44px' }}
                    />
                    :
                    <BurgerIcon />
                  }
                </button>
                <button
                  className={styles.logoButton}
                  onClick={() => onStartNewChat?.({ toChat: true })}
                  data-testid='deftgpt-sidebar-logo-btn'
                >
                  <div
                    className={classNames(styles.logoText, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                      [styles.setting]: isSettings && !isOpen,
                    })}
                  >
                    {isOpen ? 'DeftGPT' : ''}
                  </div>
                  <div
                    className={classNames(styles.logoTextsmall, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}
                  >
                    <div className={styles.mainsidehead}>
                      <div className={` ${includeRoute(RoutePaths.Chat) ? styles.sidebarSpace : styles.sidebarhead}`}>
                        <div >
                          DeftGPT
                        </div>
                        <div> <Credits /> </div>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.lgContent}>
                {includeRoute(RoutePaths.Chat) && (isOpen || width <=768)
                 ? (
                  <ChatSidebar
                    onSelectChatItem={onSelectChatItem}
                    onStartNewChat={onStartNewChat}
                    isMainScreenOpen={isMainScreenOpen}
                    toggleSidebar={toggleSidebar}
                    itemStyles={itemStyles}
                    searchQuery={searchQuery} setSearchQuery={setSearchQuery}
                    setOpenHistory={setOpenHistory}
                    sendMessageLoading={sendMessageLoading}
                    isAnswerComplete={isAnswerComplete}
                  />
                )
                  : includeRoute(RoutePaths.Chat) && !isOpen && (
                    <ChatMiniSidebar
                      onStartNewChat={onStartNewChat}
                      isMainScreenOpen={isMainScreenOpen}
                      itemStyles={itemStyles}
                    />
                  )
                }
                {includeRoute(RoutePaths.Settings) && isOpen ? <SettingSidebar
                /> : includeRoute(RoutePaths.Settings) && !isOpen && <SettingMiniSidebar
                />}
              </div>
            </div>
          </aside>
          {/* Right Pane */}
          {/* <div>
              <p>Hello</p>
            </div> */}
          {/* </CustomSplitPane> */}
          {/* <div
            className={styles.resizer}
            onMouseDown={handleMouseDown}
            onTouchStart={handleMouseDown}
          /> */}
        </div>
      </Fragment>
    );
  }
);
