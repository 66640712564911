import { useState, useEffect } from "react";
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
  UseFloatingProps,
} from "@floating-ui/react-dom";
import classNames from "classnames";
import { createPortal } from "react-dom";

import { tooltipContext } from "./context";
import styles from "./Tooltip.module.scss";
import { TooltipProps } from "./types";
import { useSelector } from "redux/hooks";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";
import { useSidebar } from "hooks/services/ReSizeSidebar/ReSizeSidebar";
import { useWindowSize } from "hooks/useWindowSize";

const MOUSE_OUT_TIMEOUT_MS = 50;

const FLOATING_OPTIONS: UseFloatingProps = {
  middleware: [offset(5), flip(), shift()],
  whileElementsMounted: autoUpdate,
};

export const Tooltip = (props: TooltipProps) => {
  const {
    children,
    control,
    className,
    disabled,
    cursor,
    theme = "dark",
    placement = "bottom",
    prompt,
    customize,
    AdjustTooltip,
    imageGeneration,
    regenrate,
    regenrateTooltip,
    tooltipAdjustement,
    AdjustregenrateTooltip,
  } = props;

  const { width } = useWindowSize();
  const themeFromStore = useSelector((state) => state.authReducer.theme);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { isOpen } = useToggleSidebar();
  const { x, y, reference, floating, strategy } = useFloating({
    ...FLOATING_OPTIONS,
    placement,
  });

  const { sidebarWidth } = useSidebar();

  useEffect(() => {
    if (isMouseOver) {
      setIsVisible(true);
      return;
    }

    const timeout = window.setTimeout(() => {
      setIsVisible(false);
    }, MOUSE_OUT_TIMEOUT_MS);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [isMouseOver]);

  const canShowTooltip = isVisible && !disabled;

  const onMouseOver = () => {
    setIsMouseOver(true);
  };

  const onMouseOut = () => {
    setIsMouseOver(false);
  };

  return (
    <>
      <div
        ref={reference}
        className={styles.container}
        style={disabled ? undefined : { cursor }}
        onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onTouchStart={onMouseOver}
      onTouchEnd={() => {
        setTimeout(() => {
          setIsVisible(false);
        }, MOUSE_OUT_TIMEOUT_MS * 10);
      }}
      >
        {control}
      </div>
      {canShowTooltip &&
        createPortal(
          <div
            role="tooltip"
            ref={floating}
            className={classNames(
              styles.tooltip,
              theme === "light" && styles.light,
              themeFromStore === "dark" && !prompt && !regenrate && styles.dark,
              className,
              themeFromStore === "light" && prompt && styles.prompt,
              themeFromStore === "dark" && prompt && styles.promptDark,
              !isOpen && prompt && styles.promptOpen,
              customize && styles.customize,
              !isOpen && customize && styles.customizeOpen,
              imageGeneration && sidebarWidth <= 400 && styles.imageGeneration,
              imageGeneration &&
              sidebarWidth > 400 &&
              styles.imageGenerationTooltip,
              regenrate && styles.regenrate,
              themeFromStore === "dark" && regenrate && styles.regenrateDark
            )}
            style={{
              position: strategy,
              top: y ?? 0,
              left: !AdjustTooltip ? x ?? 0 : " ",
              marginLeft:
                tooltipAdjustement && !AdjustregenrateTooltip
                  ? "-3px"
                  : prompt && !imageGeneration
                    ? `${(customize && width <= 1024 && width > 768) ? sidebarWidth + 40 :
                      (width <= 1024 && width > 768) ? sidebarWidth + 10 :
                      (customize && sidebarWidth <= 370 && width > 1000) ? sidebarWidth + 120 :
                        (customize && sidebarWidth <= 370 && width > 768) ? sidebarWidth + 52 :
                          (customize && sidebarWidth > 370 && width > 768) ? sidebarWidth + 60 :
                          (sidebarWidth <= 370 && width > 1000) ? sidebarWidth + 60 :
                            (sidebarWidth <= 370 && width > 768) ? sidebarWidth + 10 :
                              (width > 768) ? sidebarWidth : ''}px`
                    : "",
              marginTop: tooltipAdjustement
                ? "-5px"
                : regenrateTooltip
                  ? "-12px"
                  : "",
            }}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
          >
            <tooltipContext.Provider value={props}>
              {children}
            </tooltipContext.Provider>
          </div>,
          document.body
        )}
    </>
  );
};
