export const UploadCom = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="17"
      viewBox="0 0 23 17"
      fill="none"
    >
      <path
        d="M20.625 13.431H20.5136C20.7956 13.431 21.024 13.1977 21.024 12.9101V0.520875C21.024 0.232875 20.7956 0 20.5136 0H1.99687C1.71487 0 1.48612 0.232875 1.48612 0.520875V12.9105C1.48612 13.1981 1.71487 13.4314 1.99687 13.4314H1.875L0 15.2509C0 15.8752 0.5055 16.5 1.12388 16.5H21.3765C21.9596 16.5 22.5 15.8767 22.5 15.2505L20.625 13.431ZM11.2552 0.290625C11.2944 0.289196 11.3334 0.295664 11.3699 0.309641C11.4065 0.323619 11.4399 0.344821 11.4681 0.37198C11.4962 0.39914 11.5187 0.431701 11.534 0.467719C11.5493 0.503738 11.5572 0.542475 11.5573 0.581618C11.5573 0.620762 11.5494 0.659509 11.5341 0.695547C11.5189 0.731585 11.4965 0.764174 11.4683 0.79137C11.4402 0.818566 11.4068 0.839811 11.3703 0.853836C11.3337 0.867861 11.2947 0.874378 11.2556 0.873C11.1802 0.870342 11.1088 0.838525 11.0563 0.784246C11.0039 0.729967 10.9746 0.657462 10.9745 0.581994C10.9745 0.506525 11.0037 0.433982 11.0561 0.379636C11.1084 0.325289 11.1798 0.29338 11.2552 0.290625ZM2.02688 13.806H20.4728L21.5745 14.8755H13.3282L13.179 14.6775H9.3315L9.18188 14.8755H0.92475L2.02688 13.806ZM12.8685 16.0643H9.63075C9.51975 16.0643 9.22425 16.0643 9.22425 15.6863H13.2754C13.2754 16.0643 12.9739 16.0643 12.8685 16.0643Z"
        fill="#527AE6"
      />
    </svg>
  );
};
