import React, { Dispatch, FormEvent, SetStateAction } from "react";
import { EChatType, IChat, IChatModel } from "redux/actions";
import { PromptLibrary } from "../PromptLibrary";
import { StopGenerateButton } from "../StopGenerateButton";
import { ScrollToBottomButton } from "../ScrollToBottomButton";
import { IUploadFile, SelectedSubOptions } from "pages/ChatPage/ChatPage";
import { IFileErrorState } from "pages/ChatPage/pages";
import { ChatFooterForm } from "../chatfooterForm";

interface FilePreviewProps {
  getMessagesLoading: boolean | undefined;
  onSubmit?: ({
    event,
    message,
  }: {
    event: FormEvent<EventTarget | HTMLFormElement>;
    message: string;
  }) => void;
  onEnter?: ({
    event,
    message,
  }: {
    event: React.KeyboardEvent<HTMLTextAreaElement>;
    message: string;
  }) => void;
  isAnswerComplete?: boolean;
  isGenerating?: boolean;
  onStopGeneratingResponse?: () => void;
  isFileUploading: boolean;
  showScrollToBottom?: boolean;
  share?: boolean;
  scrollToBottom?: ({ behavior }: ScrollOptions) => void;
  settings: {
    real_time_results: boolean;
    related_questions: boolean;
    send_message_with_enter: boolean;
  };
  updateChatModel?: (modalType: EChatType) => void;
  setSelectedFile: Dispatch<SetStateAction<File[] | null>>;
  selectedFile: File[] | null;
  setIsMainScreenOpen?: Dispatch<SetStateAction<boolean>>;
  setIsFileUploading?: Dispatch<SetStateAction<boolean>>;
  IGLoading?: boolean;
  onSendMessage: (
    question: string,
    chatModels?: IChatModel,
    regenerate?: boolean,
    images?: string[],
    filePath?: string[]
  ) => void;
  openHistory?: boolean;
  setOpenHistory?: Dispatch<SetStateAction<boolean>>;
  credit: number;
  setCredit: Dispatch<SetStateAction<number>>;
  uploadingFiles?: IUploadFile[];
  fileS3Link?: string[];
  setUploadingFiles?: Dispatch<SetStateAction<IUploadFile[]>>;
  setFileS3Link?: Dispatch<SetStateAction<string[]>>;
  isAllowUploadFile?: boolean;
  chatItem?: IChat;
  selectedSubOptions: SelectedSubOptions;
  setSelectedSubOptions: Dispatch<SetStateAction<SelectedSubOptions>>;
  loadingSetting: boolean;
  showPromptModal: boolean;
  setShowPromptModal: Dispatch<SetStateAction<boolean>>;
  setMessage: Dispatch<SetStateAction<string>>;
  message: string;
  onTextareaFocus: () => void;
  promptLoading: boolean;
  userPromptLoading: boolean;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  AllowCustomizeResponse: boolean;
  isContentFound: () => boolean;
  url: string;
  setURL: Dispatch<SetStateAction<string>>;
  resetMessage: () => void;
  setUploadUrl: Dispatch<SetStateAction<boolean>>;
  setErrorModal: Dispatch<SetStateAction<IFileErrorState>>;
  handleSubmit: (event: FormEvent) => void;
  handleStartNewImageChat: () => void;
}

export const MainComponent: React.FC<FilePreviewProps> = ({
  getMessagesLoading,
  onSubmit,
  onEnter,
  isAnswerComplete,
  isGenerating,
  onStopGeneratingResponse,
  isFileUploading,
  showScrollToBottom,
  scrollToBottom,
  settings,
  updateChatModel,
  setSelectedFile,
  selectedFile,
  setIsMainScreenOpen,
  setIsFileUploading,
  onSendMessage,
  openHistory,
  setOpenHistory,
  credit,
  uploadingFiles,
  fileS3Link,
  setUploadingFiles,
  setFileS3Link,
  isAllowUploadFile,
  chatItem,
  selectedSubOptions,
  setSelectedSubOptions,
  loadingSetting,
  showPromptModal,
  setShowPromptModal,
  setMessage,
  message,
  onTextareaFocus,
  promptLoading,
  userPromptLoading,
  textareaRef,
  AllowCustomizeResponse,
  isContentFound,
  url,
  setURL,
  resetMessage,
  setUploadUrl,
  setErrorModal,
  handleSubmit,
  handleStartNewImageChat,
}) => {
  
  return (
    <>
      {showPromptModal && (
        <PromptLibrary
          onClose={() => setShowPromptModal(false)}
          setMessage={setMessage}
          onTextareaFocus={onTextareaFocus}
          promptLoading={promptLoading}
          userPromptLoading={userPromptLoading}
          textareaRef={textareaRef}
        />
      )}
      {isGenerating && !isAnswerComplete && isContentFound() && (
        <StopGenerateButton
          onClick={onStopGeneratingResponse}
          dataTestId="Stop-generating"
        />
      )}

      {!getMessagesLoading && showScrollToBottom && (
        <ScrollToBottomButton
          dataTestId="scroll-to-bottom"
          onClick={() => scrollToBottom?.({ behavior: "smooth" })}
        />
      )}

      {
        <ChatFooterForm
          onSubmit={onSubmit}
          onEnter={onEnter}
          isAnswerComplete={isAnswerComplete}
          isFileUploading={isFileUploading}
          settings={settings}
          updateChatModel={updateChatModel}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          setIsMainScreenOpen={setIsMainScreenOpen}
          setIsFileUploading={setIsFileUploading}
          onSendMessage={onSendMessage}
          openHistory={openHistory}
          setOpenHistory={setOpenHistory}
          uploadingFiles={uploadingFiles}
          fileS3Link={fileS3Link}
          setUploadingFiles={setUploadingFiles}
          setFileS3Link={setFileS3Link}
          isAllowUploadFile={isAllowUploadFile}
          chatItem={chatItem}
          selectedSubOptions={selectedSubOptions}
          setSelectedSubOptions={setSelectedSubOptions}
          loadingSetting={loadingSetting}
          showPromptModal={showPromptModal}
          setShowPromptModal={setShowPromptModal}
          setMessage={setMessage}
          message={message}
          onTextareaFocus={onTextareaFocus}
          textareaRef={textareaRef}
          AllowCustomizeResponse={AllowCustomizeResponse}
          url={url}
          setURL={setURL}
          setUploadUrl={setUploadUrl}
          resetMessage={resetMessage}
          handleSubmit={handleSubmit}
          setErrorModal={setErrorModal}
          handleStartNewImageChat={handleStartNewImageChat}
          getMessagesLoading={getMessagesLoading}
          credit={ credit}
        />
      }
    </>
  );
};
