import { useRef, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import styles from "./LeftPanel.module.scss";

import CutsomButton from "../../Button";
import { navRoutes, NavRoutesProps } from "../Header";

import { useSelector } from "redux/hooks";
import { Link } from "react-router-dom";
import { ArrowIcon } from "../Icons";

interface IProps {
  activeSection: string | null;
  onClose: () => void;
}

export const LeftPanel = ({ activeSection, onClose }: IProps) => {
  const theme = useSelector((state) => state.authReducer.theme);
  const { userDetail } = useSelector((state) => state.authReducer);
  const lang = userDetail?.user?.language ? userDetail?.user?.language : "en";
  const [isShowSubRoute, setIsShowSubRoute] = useState<string | null>(null);

  const menuRef = useRef<HTMLDivElement>(null);
  const maskRef = useRef<HTMLDivElement>(null);

  const clickCallback = (event: any) => {
    if (maskRef.current && maskRef.current?.contains(event.target)) {
      if (menuRef.current && !menuRef.current?.contains(event.target)) {
        onClose();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", clickCallback, false);
    return () => document.removeEventListener("click", clickCallback, false);
    // eslint-disable-next-line
  }, []);

   const handleNavClick = (navName: string) => {
     setIsShowSubRoute((prev) => (prev === navName ? null : navName));
   };


   const renderNavItem = (nav: NavRoutesProps) => {
     const navItemClasses = classNames(styles.navItem, {
       [styles.navItemDark]: theme === "dark",
       [styles.navItemLight]: theme === "light",
       [styles.active]: activeSection === nav.id,
     });

     return nav.subRoutes ? (
       <div key={nav.name}>
         <div
           onClick={() => handleNavClick(nav.name)}
           className={classNames(
             styles.navItem,
             styles.navSubItemHeader,
             navItemClasses
           )}
         >
           <FormattedMessage id={nav.name} />
           <span
             className={classNames(styles.arrowIcon, {
               [styles.show]: isShowSubRoute === nav.name,
             })}
           >
             <ArrowIcon />
           </span>
         </div>
         {isShowSubRoute === nav.name && (
           <ul>
             {nav.subRoutes.map((subItem) => (
               <li key={subItem.name}>
                 <Link
                   to={
                     userDetail?.token
                       ? `${subItem.path}`
                       : `/${lang}${subItem.path}`
                   }
                   onClick={onClose}
                   className={classNames(
                     styles.navItem,
                     styles.navSubItem,
                     navItemClasses
                   )}
                   data-testid={subItem.dataTestID}
                 >
                   <FormattedMessage id={subItem.name} />
                 </Link>
               </li>
             ))}
           </ul>
         )}
       </div>
     ) : (
       <Link
         key={nav.name}
         to={(userDetail?.token || nav.blog) ? `${nav.path}` : `/${lang}${nav.path}`}
         onClick={onClose}
         target={nav.blog ? "_blank" : "_self"}
         className={navItemClasses}
         data-testid={nav.dataTestID}
       >
         <FormattedMessage id={nav.name} />
       </Link>
     );
   };


  return (
    <>
      <div ref={maskRef} className={styles.mask} />
      <div
        ref={menuRef}
        className={classNames(styles.container, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
      >
        <img
          src={`/logo-primary.svg`}
          alt="logo"
          loading="lazy"
          className="max-w-[100px]"
        />
         <nav className={styles.nav}>
          {navRoutes.map(renderNavItem)}
        </nav>
        <Link
          to={`/${lang}/signup`}
          className={styles.signButton}
          data-testid="sidebar-nav-signup"
        >
          <CutsomButton variant="latest-primary">
            <FormattedMessage id="landing.nav.SignUp" />
          </CutsomButton>
        </Link>
      </div>
    </>
  );
};
