import {
  memo,
  Dispatch,
  SetStateAction,
  useRef,
  useState,
  useEffect,
} from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Question } from "./components/message";
import { ImageCarousel } from "./components/imageCarousel";
import { Answer } from "./components/messageContainer";
import { LoadingPage } from "components";
import { SpinAnimation } from "components/base/Button/LoadingButton";
import { AssistanceIcon } from "../chatHistory/components/userName";
import { Images } from "../ShareChatPage/components/Images";
import { AssistantMessageAction } from "./components/assistantMessageAction/AssistantMessageAction";
import { ShareCheckbox } from "./components/shareCheckbox";

import { IChat, IChatModel, IMessage, setSelectesMessageId } from "redux/actions";

import { useSelector } from "redux/hooks";
import { clsx } from "clsx";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import styles from "./ChatHistory.module.scss";

interface IProps {
  isAllChunksReceived?: boolean;
  isloading?: boolean;
  messages?: IMessage[];
  isGenerating?: boolean;
  setIsAnswerComplete?: Dispatch<SetStateAction<boolean>>;
  setIsGenerating?: Dispatch<SetStateAction<boolean>>;
  onSendMessage?: (
    question: string,
    chatModels?: IChatModel,
    regenerate?: boolean,
    images?: string[],
    filePath?: string[]
  ) => void;
  IGLoading?: boolean;
  onRegenerate?: (messageIndex: number, model?: IChatModel, regenerateModel?: boolean) => void;
  selectedMessages?: any;
  setSelectedMessages?: Dispatch<SetStateAction<any[]>>;
  share?: boolean;
  toggleShareChat?: () => void;
  setSelectedChatId?: Dispatch<SetStateAction<any>>;
  shareChat?: boolean;
  messageHeight?: boolean;
  setMessageHeight?: Dispatch<SetStateAction<boolean>>;
  chatItem?: IChat;
  searchQuery?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 0;
`;

export const Spinner = styled(FontAwesomeIcon)`
  display: inline-block;
  font-size: 20px;
  animation: ${SpinAnimation} 1.5s linear 0s infinite;
  color: ${({ theme }) => theme.primaryColor};
`;

export const ChatHistory = memo(
  ({
    isAllChunksReceived,
    isloading,
    messages,
    isGenerating,
    IGLoading,
    setIsAnswerComplete,
    setIsGenerating,
    onSendMessage,
    onRegenerate,
    selectedMessages,
    setSelectedMessages,
    share,
    toggleShareChat,
    setSelectedChatId,
    shareChat,
    messageHeight,
    setMessageHeight,
    chatItem,
    searchQuery,
    // onSubmit,
  }: IProps) => {
    const chatModel = useSelector((state) => state.authReducer.gptModel);
    const { shareMessages, newMessages, scrollMessageId, scrollId, isLoadMoreMessages } = useSelector(
      (state) => state.chatReducer
    );
    const { PrivateChat } = useSelector((state) => state.chatReducer);
    const messageRef = useRef<HTMLDivElement | null>(null);
    const chatContainerRef = useRef<HTMLDivElement | null>(null);
    const [highlighted, setHighlighted] = useState<boolean>(false);
    const [containerHeight, setContainerHeight] = useState<number>(0);
    const [messagee, setMessage] = useState<string>("");
    const [image, setImage] = useState<string[]>([]);
    const [file, setFile] = useState<string[]>([]);

    const calculateHeightReduction = () => {
      const reduceHeight =
        Math.round(
          ((((chatModel?.type?.includes("image") &&
            !chatModel?.type?.includes("image_chat")
            ? 318
            : 278) +
            (messageRef.current?.offsetHeight || 0)) /
            window.innerHeight) *
            100 +
            Number.EPSILON) *
          100
        ) / 100;

      let heightToReduce = reduceHeight > 48.48 ? 48.5 : reduceHeight;

      if (
        chatModel?.type?.includes("image") &&
        !chatModel?.type?.includes("image_chat")
      ) {
        heightToReduce = reduceHeight > 54.76 ? 54.8 : reduceHeight;
      }
      return heightToReduce;
    };

    const handleSubmit = () => {
      onSendMessage && onSendMessage(messagee, chatModel, false, image, file);
    };

    useEffect(() => {
      setTimeout(() => {
        if (
          chatItem?.searched_messages &&
          chatItem?.searched_messages?.length > 0
        ) {
          const targetMessageId =
            chatItem?.searched_messages && chatItem?.searched_messages.find((scrollId) => scrollId.id === scrollMessageId)
          const messageElement = chatContainerRef.current?.querySelector(
            `div[data-message-id="${targetMessageId?.id ?? null}"]`
          );

          if (messageElement) {
            messageElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
          setTimeout(() => setSelectesMessageId(0), 20)
        }
      }, 300);
    }, [messages, chatItem?.searched_messages, scrollMessageId, scrollId]);

    useEffect(() => {
      const pathname = window.location.pathname;
      if (pathname.includes("chat/new")) {
        // Find the first message with a chat_id

        const messageWithChatId = messages && messages.find((message) => message.chat_id !== undefined);
        if (messageWithChatId !== undefined && PrivateChat) {
          sessionStorage.setItem("PrivateChatId", messageWithChatId.toString());
          localStorage.setItem("PrivatehistoryChatID", messageWithChatId.toString());
        }
        if(messageWithChatId !== undefined)
        window.history.replaceState(
          null,
          "",
          `/chat/history/${messageWithChatId?.chat_id}`
        );

        // if (
        //   messages &&
        //   messages.length > 0 &&
        //   messages[1]?.chat_id !== undefined
        // ) {
        //   const chatId: number | undefined = messages[1]?.chat_id ?? messages[0]?.chat_id;

        //   if (chatId !== undefined && PrivateChat) {
        //     sessionStorage.setItem("PrivateChatId", chatId.toString());
        //     localStorage.setItem("PrivatehistoryChatID", chatId.toString());
        //   }
        //   window.history.replaceState(
        //     null,
        //     "",
        //     `/chat/history/${messages[1]?.chat_id === undefined ? messages[0]?.chat_id : messages[1]?.chat_id}`
        //   );
        // } else if (
        //   messages &&
        //   messages.length > 0 &&
        //   messages[1]?.chat_id === undefined
        // ) {
        //   if (messages[0]?.chat_id && PrivateChat) {
        //     sessionStorage.setItem("PrivateChatId", messages[0]?.chat_id.toString());
        //     localStorage.setItem("PrivatehistoryChatID", messages[0]?.chat_id.toString());
        //     window.history.replaceState(
        //       null,
        //       "",
        //       `/chat/history/${messages[0]?.chat_id}`
        //     );
        //   }
        // }
      }
    }, [messages]);

    const handleCheckboxChange = (messageId?: any) => {
      if (messageId !== undefined) {
        if (selectedMessages.includes(messageId)) {
          setSelectedMessages!(
            selectedMessages.filter((id: any) => id !== messageId)
          );
        } else {
          setSelectedMessages!([...selectedMessages, messageId]);
          {
            messages?.length === 0
              ? setSelectedChatId!(newMessages[0]?.chat_id)
              : setSelectedChatId!(messages && messages[0]?.chat_id);
          }
        }
      }
      const messageToUpdate = messages?.find(
        (message) => message.id === messageId
      );
      if (messageToUpdate && messageToUpdate.isNew) {
        setMessageHeight && setMessageHeight(false);
      }
    };

    if (isloading) return <LoadingPage />;

    return (
      <>
        <Container ref={chatContainerRef} >
          {isLoadMoreMessages && (
            <div className="flex pb-4 w-full flex-row items-center justify-center h-[15px]">
              <Spinner icon={faCircleNotch} />
            </div>
          )}

          {!shareChat
            ? messages?.map((message, index) => (
              <div
                key={message?.id}
                data-message-id={message?.id}
                className={clsx("w-full", {
                  "flex justify-end": share,
                })}
                style={{
                  minHeight:
                    message?.isNew && messageHeight
                      ? `calc(100vh - ${calculateHeightReduction()}vh)`
                      : "auto",
                  maxHeight:
                    message?.type === "assistant" &&
                      message?.isNew &&
                      share &&
                      chatModel?.type?.includes("text")
                      ? `${containerHeight}px`
                      : "",
                }}
              >
                <div
                  className={clsx("min-h-[auto]", {
                    flex: !(
                      !share &&
                      message.files &&
                      message.files.length > 0
                    ),
                    "items-center": !(
                      message?.type === "assistant" &&
                      message?.isNew &&
                      share
                    ),
                    "w-[90%]":
                      share && message.files && message.files.length > 0,
                    "w-full":
                      !share || !(message.files && message.files.length > 0),

                    "mr-0":
                      !share || !(message.files && message.files.length > 0),
                  },
                    share && (message?.files && message?.files?.length > 0) && styles.sharedocCntainer
                  )}
                  style={{
                    minHeight: "auto",
                  }}
                >
                  <div
                    className={clsx("flex-1", {
                      "w-full":
                        share && message.files && message.files?.length > 0,
                    })}
                  >
                    {message?.type === "user" && (
                      <Question
                        key={`question-${message?.id || index}`}
                        message={message}
                        messageRef={(ref) => {
                          if (index === messages?.length - 2) {
                            messageRef.current = ref;
                          }
                        }}
                        chatItem={chatItem?.searched_messages}
                        setHighlighted={setHighlighted}
                        highlighted={highlighted}
                        handleSubmit={handleSubmit}
                        setMessage={setMessage}
                        value={messagee}
                        setImage={setImage}
                        setFile={setFile}
                      />
                    )}
                    {message?.type === "assistant" &&
                      (chatModel?.type?.includes("document") ||
                        chatModel?.type?.includes("text")) && (
                        <>
                          <Answer
                            isAllChunksReceived={isAllChunksReceived}
                            key={`answer-${message?.id || index}`}
                            message={message}
                            isGenerating={isGenerating}
                            onSendMessage={onSendMessage}
                            onComplete={() => {
                              setIsAnswerComplete?.(true);
                              setIsGenerating?.(false);
                            }}
                            userMessage={
                              messages[0]?.type === "user" &&
                                !messages[0]?.content &&
                                index === 1 &&
                                messages[0]?.images &&
                                messages[0]?.images.length === 0
                                ? true
                                : false
                            }
                            onRegenerate={onRegenerate}
                            share={share}
                            messageIndex={index - 1}
                            toggleShareChat={toggleShareChat}
                            handleCheckboxChange={handleCheckboxChange}
                            chatItem={chatItem}
                            searchQuery={searchQuery}
                            setHighlighted={setHighlighted}
                            highlighted={highlighted}
                            setContainerHeight={setContainerHeight}
                          />
                        </>
                      )}
                      
                    {message?.type === "assistant" &&
                      chatModel?.type?.includes("image") &&
                      !chatModel?.type?.includes("image_chat") && (
                        <>
                          <ImageCarousel
                            share={share}
                            key={`image-${message?.id || index}`}
                            message={message}
                            IGLoading={IGLoading || false}
                          />
                          {message?.images?.length > 0 && (
                            <AssistantMessageAction
                              share={share}
                              message={message}
                              shareChat={shareChat}
                              messageIndex={index - 1}
                              toggleShareChat={toggleShareChat}
                              handleCheckboxChange={handleCheckboxChange}
                              onRegenerate={onRegenerate}
                              chatItem={chatItem}
                            />
                          )}
                        </>
                      )}
                  </div>
                  {share && (
                    <ShareCheckbox
                      message={message}
                      share={share}
                      selectedMessages={selectedMessages}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                  )}
                </div>
              </div>
            ))
            : shareMessages?.messages?.map((message, index) => (
              <div
                key={`message-${index}`}
                className={` ${(shareMessages?.messages[0]?.files?.length ?? 0) > 0
                  ? 'w-full items-center mr-0 min-h-[auto]'
                  : 'flex items-center justify-end w-full'
                  }`}
              >
                <div className="flex-1">
                  {(message?.type === "user" || (shareMessages?.messages[0]?.type === 'assistant' && shareMessages?.messages[0]?.files && shareMessages?.messages[0]?.files.length > 0)) && (
                    <Question
                      handleSubmit={handleSubmit}
                      setMessage={setMessage}
                      value={messagee}
                      key={`question-${index}`}
                      shareMessage={message}
                      shareChat={shareChat}
                      messageRef={(ref) => {
                        if (index === shareMessages?.messages.length - 2) {
                          messageRef.current = ref;
                        }
                      }}
                      assistantMessage={(shareMessages?.messages[0]?.type === 'assistant' && shareMessages?.messages[0]?.files && shareMessages?.messages[0]?.files.length > 0) ? true : false}
                    />
                  )}
                  {message?.type === "assistant" &&
                    shareMessages.chat_type !== "image" && (
                      <Answer
                        isAllChunksReceived={isAllChunksReceived}
                        shareMessage={message}
                        shareChat={shareChat}
                      />
                    )}

                  {message?.type === "assistant" &&
                    message.images &&
                    message.images.length > 0 && (
                      <div className="flex flex-row gap-5 py-3 px-5 lg:px-10">
                        <div className="px-2">
                          <AssistanceIcon />
                        </div>
                        <Images images={message.images} />
                      </div>
                    )}
                </div>
              </div>
            ))}
        </Container>
      </>
    );
  }
);
