import { useState, useEffect, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import styled from "styled-components";
import styles from "./Footer.module.scss";

import {
  DiscordIcon,
  TwitterIcon,
  AkaiIcon,
  YoutubeIcon,
  TikTok,
  Pinterest,
  Linkedin,
  facebook,
} from "./Icons";

import { DropDown, DropDownRow } from "../base";

import { languages } from "utils/languages";

import { updateLanguage } from "redux/actions";
import { useSelector } from "redux/hooks";
import { EThemeType } from "redux/reducers";
import { Link } from "react-router-dom";
import { FooterLogo } from "components/Logo/FooterLogo";
import { RoutePaths } from "pages/routePaths";

const Container = styled.div<{
  themeMode?: EThemeType;
  landingFooter?: boolean;
}>`
  background-color: ${({ themeMode, landingFooter }) =>
    landingFooter
      ? themeMode === "dark"
        ? "rgba(42, 40, 49, 0.80)"
        : "rgba(255, 255, 255, 0.30)"
      : ""};
  width: 100%;
  @media screen and (max-width: 576px) {
    background-color: ${({ themeMode }) =>
    themeMode === "dark" ? "#131314" : "#fff"};
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const FooterBody = styled.div`
  position: relative;
padding: 20px 15px;
   display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns */
  gap: 50px; /* Adjust the gap between items */

@media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
}

@media (max-width: 576px) {
    grid-template-columns: 1fr; /* 1 column on small screens */
}
  @media screen and (min-width: 1024px) {
    padding: 20px 40px;
  }
  @media screen and (min-width: 1244px) {
    padding: 20px 90px;
  }
  @media screen and (min-width: 1340px) {
    padding: 20px 146px;
  }

  // @media screen and (max-width: 1120px) {
  //   padding: 20px 140px;
  // }

  @media screen and (max-width: 900px) {
    padding: 20px 75px;
  }
  @media screen and (max-width: 799px) {
    padding: 20px 45px;
  }
  // @media screen and (max-width: 576px) {
  //   padding: 20px 25px;
  // }
  // @media screen and (max-width: 380px) {
  //   padding: 20px 15px;
  // }
`;

const SmallSelectBottom = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1120px) {
    // display: none;
    display: flex;
  }
`;

// interface FooterMenuItem {
//   id: string;
//   href: string;
//   blank?: boolean;
//   lang?: boolean;
//   dataTestId: string;
// }

interface SocialIcon {
  href: string;
  blank?: boolean;
  Icon: any;
  dataTestId: string;
}

const socialIcons: SocialIcon[] = [
  {
    href: "https://www.linkedin.com/showcase/deftgpt",
    blank: true,
    Icon: Linkedin,
    dataTestId: "deftgpt-linkedin",
  },
  {
    href: "https://www.pinterest.com/DeftGPT/",
    blank: true,
    Icon: Pinterest,
    dataTestId: "deftgpt-pinterest",
  },
  {
    href: "https://www.tiktok.com/@deftgpt",
    blank: true,
    Icon: TikTok,
    dataTestId: "deftgpt-tiktok",
  },
  {
    href: "https://www.facebook.com/DeftGPT",
    blank: true,
    Icon: facebook,
    dataTestId: "deftgpt-facebook",
  },
  {
    href: "https://discord.com/",
    blank: true,
    Icon: DiscordIcon,
    dataTestId: "deftgpt-discord",
  },
  {
    href: "https://twitter.com/DeftGPT",
    blank: true,
    Icon: TwitterIcon,
    dataTestId: "deftgpt-twitter",
  },
  {
    href: " https://www.instagram.com/official.deftgpt/",
    blank: true,
    Icon: AkaiIcon,
    dataTestId: "deftgpt-instagram",
  },
  {
    href: "https://www.youtube.com/@DeftGPT",
    blank: true,
    Icon: YoutubeIcon,
    dataTestId: "deftgpt-youtube",
  },
];


const FooterOptions = [
  {
    path: "",
    id: "feature",
    name: "landing.nav.features",
    dataTestID: "feature",
    subRoutes: [
      {
        path: `/${RoutePaths.Feature}/${RoutePaths.AccessToDiverseModels}`,
        name: "landing.nav.feature.models",
        id: "accessToDiverseModels",
        dataTestID: "feature-driverse-model",
        lang: true,
        blank: false,

      },
      {
        path: `/${RoutePaths.Feature}/${RoutePaths.ImageCreation}`,
        name: "landing.nav.feature.image",
        id: "imageCreation",
        dataTestID: "feature-image-creation",
        lang: true,
        blank: false,
      },
      {
        path: `/${RoutePaths.Feature}/${RoutePaths.DocumentBasedChat}`,
        name: "landing.nav.feature.document",
        id: "documentBasedChat",
        dataTestID: "feature-document-based-chat",
        lang: true,
        blank: false,
      },
      {
        path: `/${RoutePaths.Feature}/${RoutePaths.IntuitiveInterface}`,
        name: "landing.nav.feature.intuitive",
        id: "intuitiveInterface",
        dataTestID: "feature-intuitive-interface",
        lang: true,
        blank: false,
      },

      {
        path: `/${RoutePaths.Feature}/${RoutePaths.MultiLanguageCommunication}`,
        name: "landing.nav.feature.language",
        id: "multiLanguageCommunication",
        dataTestID: "feature-multi-language-communication",
        lang: true,
        blank: false,
      },
      {
        path: `/${RoutePaths.Feature}/${RoutePaths.ChromeExtension}`,
        name: "landing.nav.feature.extension",
        id: "chromeExtension",
        dataTestID: "feature-chrome-extension",
        lang: true,
        blank: false,
      },
    ],
  },
]

const Explore = [
  {
    path: "",
    id: "feature",
    name: "landing.footer.explore",
    dataTestID: "feature",
    subRoutes: [
      {
        path: `/#pricing`,
        name: "landing.nav.pricing",
        id: "pricing",
        dataTestID: "landing-Pricing-section",
        lang: true,
        blank: false,
      },
      {
        id: "landing.footer.affiliate",
        name: "landing.footer.affiliate",
        path: "/earn-as-affiliate",
        blank: false,
        lang: true,
        dataTestID: 'landing-footer-affiliate'
      },
      {
        id: "landing.footer.blog",
        name: "landing.footer.blog",
        path: "https://wordpress.deftgpt.com/all-blogs",
        blank: true,
        lang: false,
        dataTestID: 'landing-footer-Blog'
      },
      {
        id: "landing.footer.contact",
        path: "mailto:support@deftgpt.com",
        blank: false,
        lang: false,
        dataTestID: "landing-footer-contact",
        name: "landing.footer.contact",
      }
    ],
  },
]

const SupporOptions = [
  {
    path: "",
    id: "feature",
    name: "landing.footer.support",
    dataTestID: "support",
    subRoutes: [
      {
        id: "landing.footer.privacy",
        path: "/privacy",
        blank: false,
        lang: true,
        dataTestID: "landing-footer-privacy",
        name: "landing.footer.privacy",
      },
      {
        id: "landing.footer.terms",
        path: "/terms",
        blank: false,
        lang: true,
        dataTestID: "landing-footer-terms",
        name: "landing.footer.terms",
      },
      {
        id: "landing.footer.submitFeedback",
        path: "https://deftgpt.canny.io/feature-requests",
        blank: true,
        lang: false,
        dataTestID: "landing-footer-submitFeedback",
        name: "landing.footer.submitFeedback",
      },
    ],
  },
]

export const Footer = (
  { isHasBgOnFooter, landingFooter }: { isHasBgOnFooter?: boolean, landingFooter?: boolean }

) => {
  const [language, setLanguage] = useState<string | undefined>(
    languages[0].value
  );

  const { userDetail, theme } = useSelector((state) => state.authReducer);
  const lang = userDetail?.user?.language ? userDetail?.user?.language : "en";

  useEffect(
    () => setLanguage(userDetail?.user.language || languages[0].value),
    [userDetail]
  );

  const onUpdateLanguage = useCallback((lang: string) => {
    setLanguage(lang);
    updateLanguage(lang);
  }, []);

  const style = isHasBgOnFooter
    ? {
      backgroundImage: `url(/landing/${theme}/footer.png)`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    }
    : undefined;

  return (
    <Container themeMode={theme}
      style={style}
      landingFooter={landingFooter}
    >
      <FooterBody>
        <div className="flex flex-col ">
          <div className={styles.logoContainer}>
            <FooterLogo />
            <span className={styles.logoHead}>DeftGPT</span>
          </div>
          <SmallSelectBottom>
            <DropDown
              small={true}
              variant={theme}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  width: "130px",
                  marginTop: "-10px",
                  // TODO: temporary dirty hack
                  transform: "translate3D(0px, -344px, 0px)",
                }),
              }}
              height={44}
              options={languages}
              value={language}
              onChange={(item: DropDownRow.IDataItem) =>
                onUpdateLanguage(item.value)
              }
              className={styles.select}
            />
            <div className={styles.social__container} >
              {socialIcons.map((social, key) => {
                const Icon = social.Icon;
                return (
                  <a
                    key={key}
                    href={social.href}
                    target={social.blank ? "_blank" : "_self"}
                    className={styles.socialBtn}
                    rel="noreferrer"
                  >
                    <span
                      className={classNames(styles.icon, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}
                    >
                      <Icon />
                    </span>
                  </a>
                );
              })}
            </div>
          </SmallSelectBottom>
        </div>
        <div className="flex w-full justify-between">
          {FooterOptions.map((option, index) => (
            <div key={option.id || index} className="mb-4">
              <div className="font-bold text-lg uppercase"><FormattedMessage id={option.name} /></div>
              {option.subRoutes.map((subOption) => (
                <>
                  {subOption.name === "landing.footer.affiliate" ? (
                    <Link
                      to={
                        !userDetail?.token && subOption.lang
                          ? `/${lang}${subOption.path}`
                          : subOption.path
                      }
                      target={subOption.path ? "_blank" : "_self"}
                      className={classNames(styles.link, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}
                      data-testid={subOption.dataTestID}
                    >
                      <FormattedMessage id={subOption.name} />
                    </Link>
                  ) : (
                    <Link
                      to={subOption.lang ? `/${lang}${subOption.path}` : subOption.path}
                      target={subOption.blank ? "_blank" : "_self"}
                      className={classNames(styles.link, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}
                      rel="noreferrer"
                      data-testid={subOption.dataTestID}
                    >
                      <FormattedMessage id={subOption.name} />
                    </Link>
                  )}
                </>
              ))}
            </div>
          ))}
        </div>

        <div className="flex w-full justify-between">
          {Explore.map((option, index) => (
            <div key={option.id || index} className="mb-4">
              <div className="font-bold text-lg uppercase"><FormattedMessage id={option.name} /></div>
              {option.subRoutes.map((subOption) => (
                <>
                    <Link
                      to={subOption.lang ? `/${lang}${subOption.path}` : subOption.path}
                      target={subOption.blank ? "_blank" : "_self"}
                      className={classNames(styles.link, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}
                      rel="noreferrer"
                      data-testid={subOption.dataTestID}
                    >
                      <FormattedMessage id={subOption.name} />
                    </Link>
                </>
              ))}
            </div>
          ))}
        </div>


        <div className="flex w-full justify-between">
          {SupporOptions.map((option, index) => (
            <div key={option.id || index} className="mb-4">
              <div className="font-bold text-lg uppercase"><FormattedMessage id={option.name} /></div>
              {option.subRoutes.map((subOption) => (
                <>
                  {subOption.name === "landing.footer.affiliate" ? (
                    <Link
                      to={
                        !userDetail?.token && subOption.lang
                          ? `/${lang}${subOption.path}`
                          : subOption.path
                      }
                      target={subOption.path ? "_blank" : "_self"}
                      className={classNames(styles.link, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}
                      data-testid={subOption.dataTestID}
                    >
                      <FormattedMessage id={subOption.name} />
                    </Link>
                  ) : (
                    <Link
                      to={subOption.lang ? `/${lang}${subOption.path}` : subOption.path}
                      target={subOption.blank ? "_blank" : "_self"}
                      className={classNames(styles.link, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}
                      rel="noreferrer"
                      data-testid={subOption.dataTestID}
                    >
                      <FormattedMessage id={subOption.name} />
                    </Link>
                  )}
                </>
              ))}
            </div>
          ))}
        </div>
      </FooterBody>
    </Container>
  );
};
