import { NavRoutesProps } from "../Header";
import { IUserDetail } from "redux/actions";
import { EThemeType } from "redux/reducers";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import styles from "components/Header/Header.module.scss";
import { FeatureDropdown } from "components/Header/components/FeatureDropdown";

interface INavLinkProps {
  navRoutes: NavRoutesProps[];
  userDetail?: IUserDetail;
  theme?: EThemeType;
  activeSection: string | null;
  lang?: string;
}

export const NavLinks = ({
  navRoutes,
  userDetail,
  theme,
  activeSection,
  lang,
}: INavLinkProps) => {
  return (
    <nav>
      <ul className={styles.header__list}>
        {navRoutes.map((nav) => (
          <li key={nav.id} className={styles.header__li}>
            {nav.subRoutes ? (
              <FeatureDropdown
                userDetail={userDetail}
                theme={theme}
                nav={nav}
                activeSection={activeSection}
              />
            ) : (
              <Link
                to={(userDetail?.token || nav.blog) ? `${nav.path}` : `/${lang}${nav.path}`}
                className={classNames(styles.header__link, {
                  [styles.header__link_dark]: theme === "dark",
                  [styles.header__link_light]: theme === "light",
                  [styles.active]: activeSection === nav.id,
                })}
                target={nav.blog ? "_blank" : "_self"}
                data-target={nav.id}
                data-testid={nav.dataTestID}
              >
                <FormattedMessage id={nav.name} />
              </Link>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};
