import { useState, useEffect, useCallback, useRef, memo, useMemo } from "react";
import { useEffectOnce } from "react-use";
import styles from "./ChatPage.module.scss";
import { Navigate, Route, Routes } from "react-router-dom";

import classNames from "classnames";
import ReactGA from "react-ga4";

import { MainView } from "views/layout/MainView";
import { NewChat, ChatHistory, IErrorMessage } from "./pages";
import _ from "lodash";
import {
  ChatFooter,
  ChatFooterMethods,
} from "./components/chatFooter/chatFooter";
import { CreditLimitModal } from "./components/creditLimitModal";
import { ErrorModal } from "./components/ErrorModal";

import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";
import { useBeforeUnload } from "hooks/useBeforeUnload";
import { useNetwork } from "hooks/services/NetworkProvider";
// import { isIOS, isSafari } from "react-device-detect";

import {
  startNewChat,
  getChatHistory,
  getActivePlan,
  stopGeneratingResponse,
  IUser,
  IMessage,
  generateRelatedQuestions,
  getNewChatTopic,
  setChatModel,
  EChatType,
  ChatType,
  IChat,
  getChatSettings,
  IChatModel,
  IImage,
  IFile,
  SettingsBase,
  removeMultipleChat,
  setDropDownOptions,
  setRememberSetting,
  setErrordisplay,
  RoleBot,
  IChatHistoryResponse,
  setMessagesPagination,
  clearhistoryMessages,
  filterLanguages,
  regenerateModelNme,
  setAdminAccountDeleted,
} from "redux/actions";
import { useChatStream } from "hooks/services/ChatServices";
import { useSelector } from "redux/hooks";
import { RoutePaths } from "pages/routePaths";
import { ShareChat } from "./pages/ShareChatPage";
import { ChatSettingModal } from "./components/chatSettingModal";
import { DocumentModal } from "./pages/newChat/components/DocumentModal";
import { ErrorMessages } from "utils/functions";
import { FileSizeLimitModal } from "./components/fileSizeLimitModal";
import { getDropdownOptions, videoURL } from "utils/constants";
import { useMessageHandler } from "hooks/services/ChatServices/useMessageHandler";
import useChat from "hooks/services/ChatServices/useChat";
import { documentMaxCountReached, videoMaxCountReached } from "utils/chat";

// interfaces
interface IRoute {
  path?: string;
  component: React.ReactNode;
}

export const ChatRoute = {
  New: "new",
  History: "history",
} as const;

export interface IGetMessageAgainstKey {
  messagesArray: IMessage[];
  key: any;
}

export interface IUploadFile {
  id: string;
  file: File;
  status: "validating" | "uploading" | "uploaded" | "error";
  fileType: "document" | "image" | "video";
  S3Link?: string;
}

export interface SelectedSubOptions {
  [key: string]: string;
}

export const ChatPage = memo(() => {
  // custom hooks
  const {
    done,
    setDone,
    startStream,
    controllerRef,
    // setChatHistory,
  } = useChatStream();

  const {
    handleSelectAllChats,
    getReaminingCredits,
    getMessageAgainstKey,
    getLastMessage,
    selectedMessages,
    selectedChatId,
    messageHeight,
    setMessageHeight,
    setSelectedMessages,
    setSelectedChatId,
  } = useChat();

  const { isOnline } = useNetwork();
  const { includeRoute, pathname, push } = useRouter();
  const chatId = pathname.split("/")[3];
  const { triggerNotification } = useAppNotification();

  // data from reducer
  const chatModel = useSelector((state) => state.authReducer.gptModel);
  const chatHistory = useSelector((state) => state.chatReducer.chats);
  const { chatModels } = useSelector((state) => state.chatModelsReducer);
  const { newMessages, messages, chatSetting, PrivateChat, AdminDelErrorMsg } = useSelector(
    (state) => state.chatReducer
  );
  const currentPlan = useSelector(
    (state) => state.planSubscriptionReducer.activePlan
  );

  const { userDetail, theme, gptModel } =
    useSelector((state) => state.authReducer);

  // target DOM using ref
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const chatFooterRef = useRef<ChatFooterMethods>(null);

  // state management using useState hook
  const [creditLimitModal, setCreditLimitModal] = useState<boolean>(false);
  const toggleCreditLimitModal = () =>
    setCreditLimitModal((prev) => {
      if (prev) {
        return false;
      } else {
        ReactGA.event({
          action: "Credits_LimitReached_NotificationShown",
          category: "CreditCompletedPopupShown",
          label: "CreditCompletedPopupShown",
        });
        return true;
      }
    });
  const onConfirmCreditModal = () =>
    push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`);

  const [showScrollToBottom, setShowScrollToBottom] = useState<boolean>(false);
  const [getMessagesLoading, setGetMessagesLoading] = useState<boolean>(false);
  const [sendMessageLoading, setSendMessageLoading] = useState<boolean>(false);
  const [isAnswerComplete, setIsAnswerComplete] = useState<boolean>(true);
  const [isAllChunksReceived, setIsAllChunksReceived] = useState(false);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [IGLoading, setIGLoading] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<{
    message: string;
    show: boolean;
  }>({
    message: "",
    show: false,
  });
  const [drag, setIsDrag] = useState<boolean>(false);
  const [isMainScreenOpen, setIsMainScreenOpen] = useState<boolean>(true);
  const [docuemntModel, setDocumentModel] = useState<boolean>(true);
  const toggleDocumentModel = () => setDocumentModel((prev) => !prev);
  const [loadingShareChat, setLoadingShareChat] = useState(false);
  const [chatItem, setChatItem] = useState<IChat>();
  const [selectedFile, setSelectedFile] = useState<File[] | null>(null);
  const [showError, setShowErr] = useState<boolean>(false);

  const [credit, setCredit] = useState<number>(0);
  const [openHistory, setOpenHistory] = useState<boolean>(false);
  const [uploadingFiles, setUploadingFiles] = useState<IUploadFile[]>([]);
  const [fileS3Link, setFileS3Link] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<IErrorMessage[]>([]);
  const [messageId, setMessageId] = useState<string>("");
  const [changeModel, setChangeModel] = useState<boolean>(false);

  // Abort Generate Related Question
  const abortControllerRef = useRef<AbortController | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  // const { width } = useWindowSize();

  // STATE FOR SHARING CHAT
  const [share, setShare] = useState<boolean>(false);
  const toggleShareChat = () => setShare((prev) => !prev);

  // SEARCH CHAT HISTORY STATE
  const [searchQuery, setSearchQuery] = useState<string>("");
  const searchQueryRef = useRef(searchQuery);

  // State for chat Seeting
  const [chatSettings, setChatSetting] = useState<boolean>(false);
  const [loadingSetting, setLoadingSetting] = useState<boolean>(false);
  const [settings, setSettings] = useState({
    real_time_results: false,
    related_questions: true,
    send_message_with_enter: true,
  });
  const [selectedSubOptions, setSelectedSubOptions] = useState<SelectedSubOptions>({});

  // Message Handling Functions For Different Chat Types (Regular Chats, New Chats, IG Chats, Regular IG Chats)

  const {
    onNewTextChat,
    onRegularTextChat,
    onNewIGChat,
    onRegularIGChat
  } = useMessageHandler({
    chatId,
    newMessages,
    messages,
    setSendMessageLoading,
    setIsGenerating,
    setOpenHistory,
    setIGLoading,
    scrollToBottom: (options: { behavior: 'auto' | 'smooth' }) => {
      chatContainerRef.current?.scrollTo({ top: chatContainerRef.current?.scrollHeight, ...options });
    },
    setUploadingFiles,
    setFileS3Link,
    setIsFileUploading,
    setIsAnswerComplete,
    setIsAllChunksReceived,
    setDone,
    startStream,
    // setChatHistory,
    abortControllerRef,
    searchQuery,
  });

  useEffect(() => {
    searchQueryRef.current = searchQuery;
  }, [searchQuery]);

  useEffect(() => {
    if (errorModal.show) setErrordisplay(true);
    else setErrordisplay(false);
  }, [errorModal.show])

  useEffect(() => {
    ErrorMessages(errorMessage, setErrorModal);
  }, [errorMessage]);

  const handleContainerScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } =
        chatContainerRef.current;

      setShowScrollToBottom(
        Math.round(scrollTop + clientHeight) < Math.round(scrollHeight)
      );
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.addEventListener(
        "scroll",
        handleContainerScroll
      );
    }

    return () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.removeEventListener(
          "scroll",
          handleContainerScroll
        );
      }
    };
  }, []);

  useEffect(() => {
    if (newMessages.length > 0) setIsMainScreenOpen(false);
  }, [newMessages]);

  useEffect(() => handleContainerScroll(), [messages, newMessages]);

  const scrollToBottom = ({ behavior }: ScrollOptions) => {
    if (chatContainerRef.current) {
      const { scrollHeight } = chatContainerRef.current;
      chatContainerRef.current.scrollTo({
        top: scrollHeight,
        behavior: behavior,
      });
    }
  };

  useEffect(() => () => startNewChat(), []);

  const clearMessage = () => {
    if (chatFooterRef.current) chatFooterRef.current.resetMessage();
  };

  const onChangeChat = (online?: boolean) => {
    if (online) {
    } else clearMessage();
    setSendMessageLoading(false);
    setIsAnswerComplete(true);
    setIsAllChunksReceived(false);

    if (isGenerating) {
      setDone(true);
      onStopGeneratingResponse();
    }

    setIsGenerating(false);

    if (chatFooterRef.current) {
      userScrolled = false;
      chatFooterRef.current.onTextareaFocus()
    };
  };

  const clearPrivateChat = (chatIds: number[]) => {
    removeMultipleChat(chatIds);
    sessionStorage.removeItem("PrivateChatId");
  };

  const onGetChatHistory = useCallback(() => {
    const history = pathname.includes("/chat/history");
    const previousPrivateChat = JSON.parse(
      localStorage.getItem("previousPrivateChat") || "false"
    );
    const historyPrivateChatId = Number(
      localStorage.getItem("PrivatehistoryChatID")
    );
    const PrivateChatId = Number(sessionStorage.getItem("PrivateChatId"));
    const currentPath = window.location.pathname;
    const pathSegments = currentPath.split("/");
    const chatIdFromUrl = pathSegments[pathSegments.length - 1];

    if (previousPrivateChat && PrivateChatId && !history) {
      clearPrivateChat([PrivateChatId]);
      return;
    }

    if (
      previousPrivateChat &&
      (PrivateChatId || historyPrivateChatId) === Number(chatIdFromUrl) &&
      !PrivateChat &&
      history
    ) {
      if (PrivateChatId === Number(chatIdFromUrl))
        removeMultipleChat([PrivateChatId]);
      push(`/${RoutePaths.Chat}/${ChatRoute.New}`);
      localStorage.removeItem("previousPrivateChat");
      sessionStorage.removeItem("PrivateChatId");
      localStorage.removeItem("historyPrivateChatId");
      return;
    }

    if (chatId) {
      setTimeout(() => {
        setGetMessagesLoading(true);
      }, 0)
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create a new AbortController for this request
      abortControllerRef.current = new AbortController();

      clearhistoryMessages();
      // Abort the previous request if it exists

      if (previousPrivateChat && PrivateChatId)
        clearPrivateChat([PrivateChatId]);
      setMessagesPagination(1, 1);

      getChatHistory(
        JSON.parse(chatId),
        ({ signal: abortControllerRef.current.signal })
      )
        .then((resp) => {
          const response = resp as IChatHistoryResponse;
          const { chat } = response;

          if (chat?.id)
            setChatItem(chat);
          //  setTimeout(()=>  setGetMessagesLoading(false) ,0);
          requestAnimationFrame(() => {
            setTimeout(() => {
              scrollToBottom({ behavior: "auto" })
              handleContainerScroll();
            }, 3);
            setGetMessagesLoading(false);

          });

        })
        .catch((err: any) => {
          setGetMessagesLoading(false);
          if (err.name !== 'AbortError') {
            triggerNotification({ message: err?.data?.message, type: "error" });
            if (err?.data?.message === "Chat not found")
              push(`/${RoutePaths.Chat}/${ChatRoute.New}`);
          }
        });
    }
  }, [chatId, triggerNotification, setGetMessagesLoading]);

  const onBeforeUnload = () => {
    setDone(true);
    onStopGeneratingResponse();
  };

  useEffect(() => {
    if (!isOnline) {
      const online = true
      onChangeChat(online);
    }
  }, [isOnline]);

  useEffect(() => {
    if (chatId) {
      let chatItem = chatHistory.find((chat) => chat.id === Number(chatId));
      if (chatItem && chatModels?.length > 0) {
        if (!chatModel?.type?.includes(chatItem?.chat_type as EChatType)) {
          setChatModel(
            chatModels?.filter((item) =>
              item?.type?.includes(chatItem?.chat_type as EChatType)
            )?.[0]
          );
        }
      }
    }
  }, [chatId, chatHistory, chatModel, chatModels]);

  useBeforeUnload(() => {
    if (isGenerating) {
      onBeforeUnload();
      return true;
    }
    return false;
  });

  useEffect(() => onGetChatHistory(), [onGetChatHistory]);

  useEffectOnce(() => {
    localStorage.removeItem('invite');
    if (userDetail?.token) {
      setLoadingSetting(true);
      getChatSettings()
        .then((res) => {
          setLoadingSetting(false);
          const chatSettings = res as SettingsBase;

          const options = getDropdownOptions(
            chatSettings?.bot_settings_allowed
          );
          setDropDownOptions(options);
          filterLanguages(chatModel?.model ?? '');
          setRememberSetting(
            chatSettings?.chat_settings?.customized_response || false
          );

          setSettings({
            real_time_results:
              chatSettings?.chat_settings?.real_time_results || false,
            related_questions:
              chatSettings?.chat_settings?.related_questions || false,
            send_message_with_enter:
              chatSettings?.chat_settings?.send_message_with_enter || false,
          });
          if (chatSettings?.chat_settings?.customized_response === true) {
            setSelectedSubOptions({
              outputFormats:
                chatSettings?.bot_settings?.output_format || "default",
              tones: chatSettings?.bot_settings?.tone || "default",
              writingStyles:
                chatSettings?.bot_settings?.style_of_writing || "default",
              responseLengths: chatSettings?.bot_settings?.length || "default",
              language: chatSettings?.bot_settings?.language || "default",
            });
          }
          if (chatSettings?.chat_settings?.customized_response === false) {
            const updatedBot = {
              key: "bot_settings",
            };
            setSelectedSubOptions({
              outputFormats: 'default',
              tones: 'default',
              writingStyles: 'default',
              responseLengths: 'default',
              language: 'default',
            });
            RoleBot(updatedBot);
          }
        })
        .catch((err) => {
          setLoadingSetting(false);
          triggerNotification({ message: err?.data?.message, type: "error" });
        });
      getActivePlan().catch((err) => {
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
    }
  });

  useEffect(() => {
    localStorage.removeItem('invite');
    if (typeof done === "boolean" && done === false) {
      setIsGenerating(true);
    }
    if (done) {
      setDone(null);
      setIsAllChunksReceived(true);
      setSendMessageLoading(false);
      setIsGenerating(false);
    }
  }, [done]);

  const onSendMessage = (
    question: string,
    chatModels: IChatModel = {} as IChatModel,
    regenerate?: boolean | undefined,
    images?: string[],
    filePath?: string[]
  ) => {

    if (isFileUploading) {
      return;
    }

    let fileExtension = "";

    if (filePath && filePath.length > 0) {
      fileExtension = filePath[0]?.split('.').pop()?.toLowerCase() || "";
      if (videoURL.includes(fileExtension)) {
        if (videoMaxCountReached(currentPlan, userDetail)) {
          setMessageId!("videoChat.plan.max_count");
          setSelectedFile!(null);
          setUploadingFiles!([]);
          setIsMainScreenOpen!(true);
          return;
        }
      }
      else {
        if (documentMaxCountReached(currentPlan, userDetail)) {
          setMessageId!("documentChat.plan.max_count");
          setSelectedFile!(null);
          setUploadingFiles!([]);
          setIsMainScreenOpen!(true);
          return;
        }
      }
    }
    setShare(false);
    setMessageHeight(true);
    setChatItem(undefined);

    if (gptModel?.type.includes("text")) {
      const credits =
        Number(gptModel?.credits_per_message) +
        (images ? images?.length : 0) +
        (settings.real_time_results ? 1 : 0);
      setCredit(credits);
    }

    if (
      (question?.trim() ||
        (images && images?.length > 0) ||
        (filePath && filePath.length > 0)) &&
      !sendMessageLoading &&
      isAnswerComplete &&
      !IGLoading
    ) {
      if (
        Number(chatModel?.credits_per_message) >
        (userDetail?.user as IUser).activeSubscription.credits ||
        getReaminingCredits() <= 0
      ) {
        toggleCreditLimitModal();
      } else {
        ReactGA.event({
          action: "Chat_Message_SendButtonPressed",
          category: (userDetail?.user as IUser).activeSubscription.name,
          label: new Date().toISOString(),
          value: 4,
        });
        ReactGA.event({
          action: `Chat_Model_${chatModel?.name
            .replace(/-/g, "_")
            .replace(/\./g, "")}_MessageSent `,
          category: "MessageSentModel",
          label: chatModel?.name?.replace(/-/g, "_").replace(/\./g, ""),
        });
        if (
          chatModel?.type?.includes("image") &&
          !chatModel?.type?.includes("image_chat")
        ) {
          setIGLoading(true);
          if (chatId) onRegularIGChat(question, regenerate ?? false, chatModels);
          else onNewIGChat(question, regenerate ?? false, chatModels);
        } else {
          abortControllerRef?.current?.abort();
          setSendMessageLoading(true);
          setIsAnswerComplete(false);
          setIsAllChunksReceived(false);
          if (chatId)
            onRegularTextChat(
              question,
              chatModels,
              regenerate,
              images,
              filePath
            );
          else
            onNewTextChat(question, chatModels, regenerate, images, filePath);
        }
        clearMessage();
        if (chatFooterRef.current) {
          userScrolled = false;
          chatFooterRef.current.onTextareaFocus()
        };
      }
    }
  };

  const onSubmit = ({
    event,
    message,
  }: {
    event: React.FormEvent<EventTarget | HTMLFormElement>;
    message: string;
  }) => {
    event.preventDefault();
    onSendMessage(message);
  };

  const onEnter = ({
    event,
    message,
  }: {
    event: React.KeyboardEvent<HTMLTextAreaElement>;
    message: string;
  }) => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      onSubmit({ event: event, message });
    }
  };

  const onStopGeneratingResponse = () => {
    if (chatFooterRef.current) {
      userScrolled = false;
      chatFooterRef.current.onTextareaFocus()
    };
    const messageWithChatId = getMessageAgainstKey({
      messagesArray: newMessages,
      key: "chat_id",
    });
    abortControllerRef.current = new AbortController();

    const _chatId = chatId ? Number(chatId) : messageWithChatId?.chat_id;
    const _messageId = getLastMessage()?.id;
    if (!_chatId || !_messageId || !isGenerating) return;
    setIsAnswerComplete(true);
    setIsGenerating(false);
    setDone(true);
    setIsAnswerComplete(true);
    setIsAllChunksReceived(false);
    setIsFileUploading(false);
    setOpenHistory(false);
    regenerateModelNme('')
    controllerRef?.current?.abort();
    stopGeneratingResponse({
      chatId: chatId ? Number(chatId) : messageWithChatId?.chat_id,
      messageId: getLastMessage()?.id,
    })
      .then(() => {
        setIsAnswerComplete(true);
        setIsAllChunksReceived(true);
        setIsFileUploading(false);

        if (
          chatId &&
          chatSetting?.related_questions &&
          !abortControllerRef?.current?.signal?.aborted
        ) {
          generateRelatedQuestions({
            chatId: Number(chatId),
            messageViewType: "history",
            signal: abortControllerRef?.current?.signal,
          }).catch((err) => {
            triggerNotification({ message: err?.data?.message, type: "error" });
          });
        }
        if (
          messageWithChatId?.chat_id &&
          chatSetting?.related_questions &&
          !abortControllerRef?.current?.signal?.aborted
        ) {
          generateRelatedQuestions({
            chatId: messageWithChatId?.chat_id,
            messageViewType: "new",
            signal: abortControllerRef?.current?.signal,
          }).catch((err) => {
            triggerNotification({ message: err?.data?.message, type: "error" });
          });
        }

        if (
          !chatId &&
          newMessages.length < 3 &&
          messageWithChatId?.chat_id &&
          !PrivateChat && searchQueryRef.current === ''
        ) {
          setTimeout(() => {
            getNewChatTopic(_chatId)
              .then(() => {
              })
              .catch((err: any) => console.error(err));
          }, 4000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onGetUserMessage = (messageIndex: number) => {
    let lastQuestion = "";
    let messageImages: string[] = [];
    let docChat: string[] = [];
    if (chatId) {
      lastQuestion =
        messages[messageIndex]?.type === "user"
          ? (messages[messageIndex]?.content as string)
          : "";
      messageImages =
        messages[messageIndex]?.type === "user"
          ? (messages[messageIndex]?.images || []).map(
            (image: IImage) => image.path
          )
          : [];
      docChat =
        messages[messageIndex]?.type === "user"
          ? (messages[messageIndex]?.files || [])
            .map((file: IFile) => file.path)
            .filter((path): path is string => path !== null)
          : [];
    }
    if (!chatId) {
      lastQuestion =
        newMessages[messageIndex]?.type === "user"
          ? (newMessages[messageIndex]?.content as string)
          : "";
      messageImages =
        newMessages[messageIndex]?.type === "user"
          ? (newMessages[messageIndex]?.images || []).map(
            (image: IImage) => image.path
          )
          : [];
      docChat =
        newMessages[messageIndex]?.type === "user"
          ? (newMessages[messageIndex]?.files || [])
            .map((file: IFile) => file.path)
            .filter((path): path is string => path !== null)
          : [];
    }

    return {
      lastQuestion: lastQuestion,
      images: messageImages,
      filePath: docChat,
    };
  };

  const onRegenerate = (
    messageIndex: number,
    model: IChatModel = {} as IChatModel,
    regenerateModel?: boolean | undefined,
  ) => {
    setShare(false);
    const regenerate = regenerateModel ?? true;
    let userMessage = onGetUserMessage(messageIndex);
    if (
      userMessage.lastQuestion ||
      userMessage.images.length > 0 ||
      userMessage.filePath.length > 0
    ) {
      onSendMessage(
        userMessage.lastQuestion,
        model,
        regenerate,
        userMessage.images,
        userMessage.filePath
      );
    }
  };

  const updateChatModel = useCallback(
    (modelType: EChatType) => {
      let index = chatModels.findIndex(
        (model) =>
          model.type.includes(modelType) && model.attributes?.default === true
      );

      if (index >= 0) {
        setIsMainScreenOpen(false);
        userScrolled = false;
        chatFooterRef?.current?.onTextareaFocus?.();
        if (
          (_.isEqual(gptModel?.type, ["document", "text"]) ||
            (gptModel?.type.includes("text") &&
              gptModel?.type.includes("document"))) &&
          modelType === "document"
        ) {
        } else {
          if (modelType === "image") {
            const imageGPTModel = localStorage.getItem("imageGptModel");
            const selectedModel = imageGPTModel
              ? JSON.parse(imageGPTModel)
              : chatModels[index];
            setChatModel(selectedModel);
            if (!imageGPTModel)
              localStorage.setItem(
                "imageGptModel",
                JSON.stringify(chatModels[index])
              );
          } else {
            setChangeModel(true);
            const imageGPTModel = localStorage.getItem("documentChatGptModel");
            const selectedModel = imageGPTModel
              ? JSON.parse(imageGPTModel)
              : chatModels[index];
            localStorage.setItem(
              "GptModel",
              imageGPTModel || JSON.stringify(chatModels[index])
            );
            setChatModel(selectedModel);
          }
        }
        if (modelType === "document" && newMessages.length > 0) {
          startNewChat();
        }
      }
    },
    [chatModels, newMessages, gptModel, chatFooterRef?.current?.onTextareaFocus]
  );

  // Use useMemo to cache the result of isDisabled calculation
  const isAllowUploadFile = useMemo(() => {

    if (gptModel?.type.includes(ChatType.image)) {
      return false;
    }
    const history = pathname.includes("/chat/history");
    if (
      (chatItem && history) ||
      (history && !messages[0]?.images && !messages[0]?.files)
    ) {
      if (
        chatItem?.chat_type === ChatType.image ||
        chatItem?.chat_type === ChatType.text ||
        (!messages[0]?.images && !messages[0]?.files)
      ) {
        return false;
      }
    }
    // new Chat
    if (newMessages.length > 0) {
      const firstMessage = newMessages[0];
      if (
        (firstMessage?.images?.length === 0 && !firstMessage?.files) ||
        (firstMessage?.files &&
          firstMessage?.files?.length === 0 &&
          firstMessage?.images?.length === 0)
      ) {
        return false;
      }
    }
    return true;
  }, [gptModel, chatItem, newMessages.length, uploadingFiles, messages]);

  const onConfirm = () =>
    push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`);
  const onCancel = () => setMessageId("");

  useEffect(() => {
    const handleUpdate = (e: any) => {
      const settings = e.detail;
      if (settings?.key === "bot_settings") {
        setSelectedSubOptions(settings);
      }
      if (settings?.key === "chat_settings") {
        setSettings(settings);
        setRememberSetting(settings?.customized_response)
      }

    };
    document.addEventListener("updatedSettings", handleUpdate);
    return () => {
      document.removeEventListener("updatedSettings", handleUpdate);
    };
  }, []);

  let userScrolled = false;

  const routes: IRoute[] = useMemo(() => {
    return [
      {
        path: `${ChatRoute.New}`,
        component: (
          <NewChat
            isMainScreenOpen={isMainScreenOpen}
            isAllChunksReceived={isAllChunksReceived}
            newMessages={newMessages}
            isGenerating={isGenerating}
            isFileUploading={isFileUploading}
            IGLoading={IGLoading}
            setIsAnswerComplete={setIsAnswerComplete}
            setIsGenerating={setIsGenerating}
            onSendMessage={onSendMessage}
            onRegenerate={onRegenerate}
            docuemntModel={docuemntModel}
            toggleDocumentModel={toggleDocumentModel}
            selectedMessages={selectedMessages}
            setSelectedMessages={setSelectedMessages}
            share={share}
            toggleShareChat={toggleShareChat}
            setSelectedChatId={setSelectedChatId}
            messageHeight={messageHeight}
            setMessageHeight={setMessageHeight}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            updateChatModel={updateChatModel}
            drag={drag}
            setSelectedFile={setSelectedFile}
            setIsMainScreenOpen={setIsMainScreenOpen}
            setIsDrag={setIsDrag}
            setOpenHistory={setOpenHistory}
            setUploadingFiles={setUploadingFiles}
            setFileS3Link={setFileS3Link}
            setIsFileUploading={setIsFileUploading}
            uploadingFiles={uploadingFiles}
            changeModel={changeModel}
            setChangeModel={setChangeModel}
          />
        ),
      },
      {
        path: `${ChatRoute.History}/:chatId`,
        component: (
          <ChatHistory
            isAllChunksReceived={isAllChunksReceived}
            isloading={getMessagesLoading}
            messages={messages}
            isGenerating={isGenerating}
            setIsAnswerComplete={setIsAnswerComplete}
            setIsGenerating={setIsGenerating}
            onSendMessage={onSendMessage}
            IGLoading={IGLoading}
            onRegenerate={onRegenerate}
            selectedMessages={selectedMessages}
            setSelectedMessages={setSelectedMessages}
            share={share}
            toggleShareChat={toggleShareChat}
            setSelectedChatId={setSelectedChatId}
            messageHeight={messageHeight}
            setMessageHeight={setMessageHeight}
            chatItem={chatItem}
            searchQuery={searchQuery}
          />
        ),
      },
    ];
  }, [
    isAllChunksReceived,
    newMessages,
    messages,
    getMessagesLoading,
    isGenerating,
    isFileUploading,
    IGLoading,
    isMainScreenOpen,
    onRegenerate,
    onSendMessage,
    setIsGenerating,
    setIsAnswerComplete,
    setIsFileUploading,
    setIsMainScreenOpen,
  ]);
  return (
    <MainView
      sendMessageLoading={sendMessageLoading}
      isAnswerComplete={isAnswerComplete}
      onChangeChat={onChangeChat}
      contentContainerRef={chatContainerRef}
      isMainScreenOpen={isMainScreenOpen}
      setIsMainScreenOpen={setIsMainScreenOpen}
      docuemntModel={docuemntModel}
      setShare={setShare}
      setSelectedMessages={setSelectedMessages}
      setChatItem={setChatItem}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      setChatSetting={setChatSetting}
      updateChatModel={updateChatModel}
      setIsDrag={setIsDrag}
      setSelectedFile={setSelectedFile}
      isFileUploading={isFileUploading}
      setOpenHistory={setOpenHistory}
      controllerRef={controllerRef}
      uploadingFiles={uploadingFiles}
      setUploadingFiles={setUploadingFiles}
      setFileS3Link={setFileS3Link}
      isAllowUploadFile={isAllowUploadFile}
      fileS3Link={fileS3Link}
      setSelectedSubOptions={setSelectedSubOptions}
      selectedSubOptions={selectedSubOptions}
      textareaRef={textareaRef}
      userScrolled={userScrolled}
    >
      <div
        className={classNames(styles.container, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
          [styles.isShare]: theme === "light" && includeRoute("/share-chat/"),
          [styles.content]:
            (includeRoute(ChatRoute.New) &&
              newMessages.length === 0 &&
              !isFileUploading) ||
            (getMessagesLoading && !isFileUploading) ||
            uploadingFiles.length > 0,
        })}
      >
        {!window.location.pathname.includes("share-chat") && (
          <Routes>
            {routes.map(({ path, component: Component }) => (
              <Route key={path} path={path} element={Component} />
            ))}
            <Route path="*" element={<Navigate to={ChatRoute.New} replace />} />
          </Routes>
        )}
        {window.location.pathname.includes("share-chat") && (
          <ShareChat
            loadingShareChat={loadingShareChat}
            setLoadingShareChat={setLoadingShareChat}
          />
        )}
      </div>

      <ChatFooter
        ref={chatFooterRef}
        onSubmit={onSubmit}
        getMessagesLoading={getMessagesLoading}
        onEnter={onEnter}
        isAnswerComplete={isAnswerComplete}
        isGenerating={isGenerating}
        onStopGeneratingResponse={onStopGeneratingResponse}
        isFileUploading={isFileUploading}
        showScrollToBottom={showScrollToBottom}
        scrollToBottom={scrollToBottom}
        share={share}
        selectedMessages={selectedMessages}
        toggleShareChat={toggleShareChat}
        handleSelectAllChats={handleSelectAllChats}
        selectedChatId={selectedChatId}
        loadingShareChat={loadingShareChat}
        setSelectedMessages={setSelectedMessages}
        settings={settings}
        updateChatModel={updateChatModel}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        setIsMainScreenOpen={setIsMainScreenOpen}
        setIsFileUploading={setIsFileUploading}
        IGLoading={IGLoading}
        onSendMessage={onSendMessage}
        openHistory={openHistory}
        setOpenHistory={setOpenHistory}
        credit={credit}
        setCredit={setCredit}
        uploadingFiles={uploadingFiles}
        fileS3Link={fileS3Link}
        setUploadingFiles={setUploadingFiles}
        setFileS3Link={setFileS3Link}
        isAllowUploadFile={isAllowUploadFile}
        chatItem={chatItem}
        selectedSubOptions={selectedSubOptions}
        setSelectedSubOptions={setSelectedSubOptions}
        loadingSetting={loadingSetting}
        textareaRef={textareaRef}
      />
      {creditLimitModal && (
        <CreditLimitModal
          onCancel={toggleCreditLimitModal}
          onClose={toggleCreditLimitModal}
          onConfirm={onConfirmCreditModal}
        />
      )}
      {chatSettings && (
        <ChatSettingModal
          onClose={() => setChatSetting(false)}
          loadingSetting={loadingSetting}
          settings={settings}
          setSettings={setSettings}
        />
      )}
      {(errorModal.show || AdminDelErrorMsg) && (
        <ErrorModal
          uploadURL={showError || ErrorMessages.length > 0 ? true : false}
          message={errorModal.message}
          onClose={() => {
            setErrorModal({ message: "", show: false });
            setErrorMessage([]);
            setAdminAccountDeleted(false);
          }}
        />
      )}

      {drag && (newMessages.length > 0 || chatId) && (
        <div
          className={classNames(styles.documentModalContainer, {
            [styles.dragDocument]: drag,
          })}
        >
          <DocumentModal
            chatPage={true}
            setSelectedFile={setSelectedFile}
            setIsMainScreenOpen={setIsMainScreenOpen}
            setIsDrag={setIsDrag}
            setErrorModal={setErrorModal}
            setShowErr={setShowErr}
            setOpenHistory={setOpenHistory}
            setUploadingFiles={setUploadingFiles}
            setIsFileUploading={setIsFileUploading}
            setFileS3Link={setFileS3Link}
            setErrorMessage={setErrorMessage}
            uploadingFiles={uploadingFiles}
            setMessageId={setMessageId}
            chatItem={chatItem}
          />
        </div>
      )}
      {messageId && (
        <FileSizeLimitModal
          messageId={messageId}
          onCancel={onCancel}
          onClose={onCancel}
          onConfirm={onConfirm}
        />
      )}
    </MainView>
  );
});