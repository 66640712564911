import { IImage, ISharImgeChat } from "redux/actions";
import styles from "./answerContainer.module.scss";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { useEffect } from 'react';
import { Spinner } from "components";
import 'react-photo-view/dist/react-photo-view.css';

interface IProp {
    images: IImage[] | ISharImgeChat[];
    loading: boolean;
    handleImageLoad: () => void;
}

export const ImagePreview = ({ images, loading, handleImageLoad }: IProp) => {

    useEffect(() => {
        // Function to adjust z-index of PhotoView-Portal elements
        const adjustPhotoViewPortalZIndex = () => {
            const portals = document.getElementsByClassName('PhotoView-Portal');
            Array.from(portals).forEach(portal => {
                if (portal instanceof HTMLElement) {
                    portal.style.zIndex = '90000';
                    portal.style.position = 'absolute';
                }
            });
        };

        // Create a MutationObserver to watch for DOM changes
        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    adjustPhotoViewPortalZIndex();
                }
            }
        });

        observer.observe(document.body, { childList: true, subtree: true });
        adjustPhotoViewPortalZIndex();

        return () => {
            observer.disconnect();
        };
    }, []);

    if (images.length === 0) return null;

    return (
        <>
            <PhotoProvider maskOpacity={0.91}>
                {images.map((image, index) => (
                    <>
                        {loading && (
                            <div>
                                {" "}
                                <Spinner extraSmall delFile/>{" "}
                            </div>
                        )}
                        <PhotoView key={index} src={image.path}>

                            <img
                                src={image.path}
                                alt={`Image ${index + 1}`}
                                className={styles.image}
                                onLoad={handleImageLoad}
                            />
                        </PhotoView>
                    </>
                ))}
            </PhotoProvider>
        </>
    )
};
