import React from 'react'

export const TableCopyIcon: React.FC = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.125 20.1438C3.75833 20.1438 3.4375 20.0063 3.1625 19.7313C2.8875 19.4563 2.75 19.1354 2.75 18.7688V4.95001H4.125V18.7688H14.9875V20.1438H4.125ZM6.875 17.3938C6.50833 17.3938 6.1875 17.2563 5.9125 16.9813C5.6375 16.7063 5.5 16.3854 5.5 16.0188V3.18542C5.5 2.81876 5.6375 2.49792 5.9125 2.22292C6.1875 1.94792 6.50833 1.81042 6.875 1.81042H16.9583C17.325 1.81042 17.6458 1.94792 17.9208 2.22292C18.1958 2.49792 18.3333 2.81876 18.3333 3.18542V16.0188C18.3333 16.3854 18.1958 16.7063 17.9208 16.9813C17.6458 17.2563 17.325 17.3938 16.9583 17.3938H6.875ZM6.875 16.0188H16.9583V3.18542H6.875V16.0188Z"
      fill="currentColor"
    />
  </svg>
)